import React from 'react';
import Merchants from './merchants'
import MerchantDetails from './merchantdetails';
import SoftwareGroups from './softwaregroups';
import Terminals from './terminals';
import Administration from './admin';
import TerminalList from './terminallist';
import ZinaraSettings from './zinara';
import Reports from './mushumo';
import OsUpdate from './firmwareupdate';

import Cards from './cards';
import {ShowMsg} from './pop';
import GroupSettings from './groupsettings';
import EmvLimits from './emvlimits';
//console.log("Before Socket connect here!!!");
//const socket = io(url);
class Screen extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      socket:null,
    }
    }

    async componentDidMount() {
      console.log('Screen Component DID MOUNT!')
      window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
   }

    connect = async (soc)=>{
      this.setState({socket:soc});
    }
      render(){
        const idx = this.props.idx;
        switch(idx){
          case 0:
          return(<Administration />)
          case 1:
            return <Merchants/>
          case 2:
            return(<Terminals/>)
          case 3:
            return <OsUpdate />
          case 4:
            return(<Cards/>)
          case 5:
            return <SoftwareGroups />
          case 6:
            return <TerminalList rows={10} />
          case 7:
            return <GroupSettings/>
          case 8:
            return <ZinaraSettings/>
          case 9:
            return <Reports/>
          case 10:
            return <EmvLimits/>
          default:
          return(<h3>Dashboard Screen</h3>)
         

        }
      }
}

export default Screen;
