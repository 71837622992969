import {React} from 'react'
import {Navigate,Outlet,useLocation} from 'react-router-dom'

function ProtectedRoute(isAuth,...rest){
  let loc =useLocation();
  console.log("Is Auth Value: "+JSON.stringify(isAuth));
  return( 
    isAuth.auth? <Outlet/> : <Navigate to='/auth' state={{from:loc}}/>
 )
  

}
export default ProtectedRoute;
