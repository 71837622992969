import React, {useState,useEffect} from 'react'
import {Card,Row,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Users from './users';
import Profiles from './profiles';
import {EventAllowed} from './profilecfg';
import ChangePassword from './passwords';
import {Url} from './url';
import Axios from 'axios';
import {ROLEOPS} from './constants/constants';

function Administration ({...props}){

const [mid,setMid] = useState(1);
const [ok,setOk] = useState(true);

const [entries,setEntries]= useState({});
const [changes,setChanges]= useState({});
const [views,setViews]= useState({});
const [gotroles, setGotRoles]= useState(false);
// eslint-disable-next-line react-hooks/exhaustive-deps
const getRolesHandler = async ()=>{
  // await timeout(2000);
   var data={roleid: sessionStorage.getItem('role')};
      var url =Url+'/getroles';
      console.log("geroles url: "+url);
   console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
   Axios.post(url,data).then((res)=>{
     console.log('get roles Response received: '+JSON.stringify(res.data));
     if(res.data.msg==='ok'){
         console.log('profile data received OK!!');
         setGotRoles(true);
         if(res.data.roles.length>0){
         setEntries(res.data.roles[0]);
         setChanges(res.data.roles[1]);
         setViews(res.data.roles[2]);
      
       }else{
         clearRoles();
       }
 
     }
   });
 };
 
 
 useEffect( ()=>{  
  window.onkeydown = (e)=>{
    if(e.ctrlKey === true || e.metaKey === true){
      if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
      e.preventDefault();
    }
   }
   async function fetchdata(){
     console.log("Admin getting roles here!!");
     await getRolesHandler();
   }
   if(!gotroles)   
   fetchdata();
 },[getRolesHandler, gotroles]);
 
 const clearRoles =()=>{
   setEntries({});
   setChanges({});
   setViews({});
   console.log("Views.user: "+views.user);
 }
 

  return(

    <Container className="mt-2 pb-5" fluid>
    <Row>
    <Col  sm={12}  className="text-center"><span className="App-header shadow-sm">ADMINISTRATION  </span></Col>
    </Row>
    <Row>
    <Col  sm={12}>
    <Card className=" pb-1">
    <Card.Body>
    <Tabs className="pb-3" forceRenderTabPanel defaultIndex={2}>
          <TabList>
          <Tab disabled={!(EventAllowed(entries,ROLEOPS.USER) || EventAllowed(changes,ROLEOPS.USER) || EventAllowed(views,ROLEOPS.USER))}>Users</Tab>
          <Tab disabled={!(EventAllowed(entries,ROLEOPS.PROFILE) || EventAllowed(changes,ROLEOPS.PROFILE) || EventAllowed(views,ROLEOPS.PROFILE))}>Profiles</Tab>
          <Tab>Change Own Password</Tab>
          </TabList>
        <TabPanel>
        <Users name={'Edwin Dzamara'} e={entries} c={changes} v={views}/>
        </TabPanel>
        <TabPanel>
        <Profiles />
        </TabPanel>
        <TabPanel>
        <ChangePassword />
        </TabPanel>
    </Tabs>

    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

  )
}

export default Administration;
