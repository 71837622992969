import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
import { Redirect } from "react-router";
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { formatDate, toDate } from 'date-utils-2020'
function MerchantMushumo(...props){

    const products =[{'name': 'OK Zimbabwe','mid':'OK East Gate','addr':'cnr 3rd & Robert Mugabe'},
                     {'name': 'TM','mid':'TM First Street','addr':'cnr 1sd & Jason Moyo'},
                    ];
     const columns =[{
        dataField:'mid',
        text:'Merchant ID', 
        sort: true 
     },{
        dataField:'name',
        text:'Merchant Name',
        sort: true,
        headerStyle: (colum, colIndex) => {
            return { width: '250px', textAlign: 'center' };
          },
        filter: textFilter()
     },{
        dataField:'address',
        text:'Address',
        headerStyle: (colum, colIndex) => {
            return { width: '250px', textAlign: 'center' };
          },
        filter: textFilter()
     },{
        dataField:'currencycode',
        text:'Currency', 
        sort: true 
     },{
        dataField:'cards',
        text:'Card Service Group', 
        sort: true 
     },{
        dataField:'comms',
        text:'Host Config', 
        sort: true 
     },{
        dataField:'promo',
        text:'Promo Group', 
        sort: true 
     },{
        dataField:'ttype',
        text:'Terminal Type', 
        sort: true 
     },{
      dataField:'created',
      text:'Creation Date', 
      sort: true 
   }]

     const MyExportCSV = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
          <div>
            <button className="btn btn-info" onClick={ mrefresh }>Refresh</button>{' '}
            <button className="btn btn-outline-info" onClick={ handleClick }>Export to CSV</button>
          </div>
        );
      };
    const [firstrun,setFirstRun]=useState(true);
    const [mushumo,setMushumo]=useState([]);
    const [spin,setSpin]=useState(true);


    const mrefresh = ()=>{
      setMushumo([]);
      refresh(false);
    }

    const refresh =(silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/getmerchreport';
     const data ={};
     setSpin(true);
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("received mushumo: "+JSON.stringify(res.data.list));
            setMushumo(res.data.list);
         }
         setSpin(false);
     });
    }
    //to do this on every page render
   
useEffect(()=>{ 
  /*window.onkeydown = (e)=>{
    if(e.ctrlKey === true){
      e.preventDefault();
    }
   }*/
   window.onkeydown = (e)=>{
    if(e.ctrlKey === true || e.metaKey === true){
      if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
      e.preventDefault();
    }
   }
    async function refreshdata(){
        console.log("getting toll fees here!!");
        await refresh(true);
        //console.log("toll data: "+lightvehicles);
      };
      if(firstrun){
        refreshdata();
      }
},[]);


   
const NoDataIndication = () => (
  <div className="spinner">
    <div className="rect1" />
    <div className="rect2" />
    <div className="rect3" />
    <div className="rect4" />
    <div className="rect5" />
  </div>
);
    
  

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnupdate':
          //updatetollfees();
          break;
          case 'btnrefresh':
         refresh(false);
          break;
          default:
        }
    }
   
    return(
        <Container className="mt-2" fluid>
            <ToolkitProvider
  keyField="id"
  data={ mushumo }
  columns={ columns }
  exportCSV={{
    fileName:'MerchantsReport_'+formatDate(new Date(),'yyyy-MM-dd_hh:mm:ss')+'.csv',
    onlyExportFiltered: true, exportAll: false
  }}
>
  {
    props => (
      <div>
        <BootstrapTable
        { ...props.baseProps }
             filter={filterFactory()}
             pagination={ paginationFactory() }
             noDataIndication={ () => <NoDataIndication /> }
             striped
             hover
             condensed
            />
            {showSpinDiag(spin,'Fetching Merchant record(s)')}
        <hr />
        <MyExportCSV { ...props.csvProps } />
      </div>
    )
  }
</ToolkitProvider>
            
    </Container>
    )


}
export default MerchantMushumo;