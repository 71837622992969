import React from 'react';
import ReactDOM from 'react-dom';
import {Form,Button,Card,Row,Container,Col} from 'react-bootstrap';
import Swal from 'sweetalert2'
import Axios from 'axios'
import { Navigate } from "react-router-dom";
import {Url} from './components/url';
class RegForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      userid: '',
      userpass:'',
      conpass:'',
      fname:'',
      lname:'',
      mobile:'',
      email:'',
      locked:false,
      roleid:'0',
      redirect:false
    }
  }

  clearForm=()=>{
    this.setState({
      userid:'',
      userpass:'',
      conpass:'',
      fname:'',
      lname:'',
      mobile:'',
      email:'',
      locked:false,
      roleid:'0',
    })
  }
  userIdHandler = event=>{
    this.setState({
      userid: event.target.value
    })
  }
  passwordHandler =event=>{
    this.setState({
      userpass:event.target.value
    })
  }

  conpasswordHandler =event=>{
    this.setState({
      conpass:event.target.value
    })
  }

  firstnameHandler =event=>{
    this.setState({
      fname: event.target.value
    })
  }
  lastnameHandler =event=>{
    this.setState({
      lname: event.target.value
    })
  }
  mobileHandler =event=>{
    this.setState({
      mobile: event.target.value
    })
  }

  emailHandler =event=>{
    this.setState({
      email: event.target.value
    })
  }
  lockstatuseHandler =event=>{
    this.setState({
      locked: event.target.value
    })
  }
  userroleHandler =event=>{
    this.setState({
      roleid: event.target.value
    })
  }
  showMsg(msg,isOk){
    Swal.fire(
      {
        title:'User Registration',
        text: msg,
        icon:isOk?'success':'error',
      confirmButtonText:'OK'
      }
    )
  }
  signinHandler =()=>{
    console.log("going to login here!!!");
    this.setState({ redirect: true });
  }

  signupHandler =()=>{
    if(this.allFieldsOk()===false)
    return;
    if(this.passwordMatching() === false)
    return;
    var url =Url+'/reg'
    console.log('Doing Sign Up POST here!!!');
    Axios.post(url,{
      userid: this.state.userid,
      userpass:this.state.userpass,
      fname:this.state.fname,
      lname:this.state.lname,
      mobile:this.state.mobile,
      email:this.state.email,
      locked:this.state.locked,
      roleid:this.state.roleid
    }).then((res)=>{
      console.log('Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
        this.showMsg('Registration was Successful!!',true);
         this.clearForm();
      }else{
        this.showMsg('Registration Failed!!',false);
      }
    });
  }

  passwordMatching =()=>{
    if(this.state.userpass === this.state.conpass){
    return true;
  }
    else {
        this.showMsg('Passwords Mismatch. Please retry!!',false);
        this.setState({
          userpass:'',
          conpass:'',
        });
      return false;
    }
  }

  allFieldsOk =()=>{
    if(this.state.userid==='' || this.state.userpass===''|| this.state.fname===''|| this.state.lname==='' || this.state.mobile==='' || this.state.email===''){
      console.log('Fields are empty!!!');
      this.showMsg('Please Fill up all Fields!!',false);
    return false;
  }
    else {
      console.log('Fields NOT empty!!!');
      return true;
    }
  }
  render(){
    const {redirect} = this.state;
    if(redirect){
    return (
      <Navigate to='/' />
    )
    }
    return (
      <Container className="mt-4">
      <Row>
      <Col></Col>
      <Col xs={6} className="text-center"><span className="App-header shadow-sm">USER REGISTRATION</span></Col>
      <Col></Col>
      </Row>
      <Row>
      <Col></Col>
      <Col xs={6}>
      <Card className="shadow-sm bg-white rounded pb-3">
      <Card.Body>
      <Form>
      <Row>
      <Col >
      <Form.Group>
      <Form.Label>First Name</Form.Label>
      <Form.Control type="text" placeholder="First Name..." value={this.state.fname} onChange={this.firstnameHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Last Name</Form.Label>
      <Form.Control type="text" placeholder="Last Name..." value={this.state.lname} onChange={this.lastnameHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Mobile Number</Form.Label>
      <Form.Control type="text" placeholder="Mobile Number..." value={this.state.mobile} onChange={this.mobileHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Email Address</Form.Label>
      <Form.Control type="text" placeholder="Email Address..." value={this.state.email} onChange={this.emailHandler}/>
      </Form.Group>
      </Col>
      <Col>
      <Form.Group>
      <Form.Label>User Name</Form.Label>
      <Form.Control type="text" placeholder="User Name..." value={this.state.userid} onChange={this.userIdHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>New User Password</Form.Label>
      <Form.Control type="password" placeholder="New User Password..." value={this.state.userpass} onChange={this.passwordHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Confirm New Password</Form.Label>
      <Form.Control type="password" placeholder="Confirm New Password..." value={this.state.conpass} onChange={this.conpasswordHandler}/>
      </Form.Group>
      </Col>
      </Row>

      <Button className="pr-5 pl-5" variant="info" size="sm" onClick={this.signupHandler}>Sign Up</Button>       <Button className="ml-2 pr-5 pl-5" variant="outline-info" size="sm" onClick={this.signinHandler}>Sign In</Button>
      </Form>
      </Card.Body>
      </Card>
      </Col>
      <Col></Col>
      </Row>
      </Container>
    )
  }
}
export default RegForm;
