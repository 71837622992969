import {useState,useEffect,useLayoutEffect,React} from 'react';
import {Tabs,Table,Tab,Form,Button,Card,Row,Container,Col} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg} from './pop';
import {Url} from './url';
import FeatherIcon from 'feather-icons-react';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { io } from "socket.io-client";
const url ='http://10.0.0.6:8000';
//console.log("Before Socket connect here!!!");
  var socket;
  var refreshInterval;
function TerminalList(...props){
  const [pos,setPos]=useStateWithCallbackLazy([]);
  const [terminals,setTerminals]=useState([]);
  const [pages,setPages]=useState(0);
  const [rows,setRows] =useState(props.rows);
  const [sdata,setSdata]=useState('');
  const [isFirstTime,setFirsttime]=useState(true);
  const [isOnline,setOnline]=useState(true);
  const [tid,setTid]=useState(0);
  const [cpos,setCPos]=useState({});
  var tm ={};




   const socketClientInit =()=>{

     console.log("Doing Socket Registration!!");
     socket.on('connect',()=>{
       console.log("client connected!! OK");
       socket.emit('joinRoom',{ptid:'tmsportal',roomname:'tms'})
     })





     socket.on('connect_error',()=>{
       console.log("getting a connect error here!!");
       //socket.disconnect()
     })

     socket.on("disconnect", () => {
       console.log("client disconnected here!!");
     console.log(socket.connected); // false
     //socket.connect();
   });
     socket.on('message',(dat)=>{
       console.log('Event=>message: here: '+ JSON.stringify(dat));
        socket.emit("chat",{msg:'online'});
     });

     socket.on('listUpdate',(dat)=>{
       console.log('Pos List here: '+ JSON.stringify(dat));
          let tmp =[];
          let days ='';
          while(pos.length){
            pos.pop();
          }
          //setPos([]);
          console.log("pos size: "+pos.length);
       if(dat.length>0){
         for(var i=0; i<dat.length; i++){
           if(dat[i].lastupdate !== ''){
             //process time passed since last update here
             days =getLastupdateNote(dat[i].lastupdate);
             console.log("Last update note: "+days);
           }else{
             days="Never";
           }
         tmp.push({ptid:dat[i].ptid,model:dat[i].model,status:dat[i].status,lastupdate:days});
       }
     }
     if(pos.length)
     console.log("pos[0].ptid: "+ pos[0].lastupdate);
     setPos(tmp,()=>{
       console.log("done assigning: "+pos.model);
     });
     });



     socket.on('chat',(dat)=>{
       console.log('Received response here!!: '+ JSON.stringify(dat));
       const cmd={msg:'online'}
       socket.emit('chat',cmd);
     });



     socket.on('ping',(dat)=>{
        console.log("received ping: "+JSON.stringify(dat));
        pingHandler();
     });
   }

   const getLastupdateNote =(updateDate)=>{

     const oldDate = new Date(updateDate);
     console.log("old date: "+oldDate);
     const now = new Date();
     console.log("Now: "+now);
      var diff = (now-oldDate);
     diff = Math.abs(Math.floor(diff));;
     console.log("time diff: "+diff);

     // math
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    if(days>0)
    {
      return days +" days ago";
    }
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if(hours>0){
      return hours + "hours ago";
    }
    let minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if(minutes>0){
      return (minutes<2)? minutes + " minute ago":minutes + " minutes ago";
    }
    let seconds = Math.floor((diff % (1000 * 60)) / 1000);
    if(seconds<10){
      return "just now";
    }else if(seconds> 10){
      return "few moments ago";
    }


   }

   const refreshHandler =()=>{

     const cmd={cmd:0x01,ptid:'*'};
    console.log("refresh handler clicked: msg: "+JSON.stringify(cmd));
     socket.emit('ping',cmd,(data)=>{
       console.log("message received after emit: "+data);
       if(!data){
         refresh();
       }
     });
   }

  const findHandler =(e)=>{
    e.preventDefault();
    return;
  }

  const filterHandler =(e)=>{
    const val = e.target.value;
    setSdata(val);
    return;
}

const getLastUpdate=(val)=>{

}

const getStatus= (s)=>{
  if(s === '')
  return 'offline';
  else if(s==='online' || s.startsWith('Download'))
  return 'online';
  else
  return s;

}

const refresh =()=>{
  var url =Url+'/getterminals';
  const data={};
  console.log("Doing Terminal refresh here!!!");
  Axios.post(url,data).then((res)=>{
    console.log('Terminals refresh Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Export OK!!'+ JSON.stringify(res.data.list));
        setTerminals(res.data.list);
        console.log("terminals size: "+res.data.list.length);
        setPos(res.data.list);
        //const ps =res.data.list;

    }else{
      console.log('Terminal Export failed!!!');
    }
  });

}

const pingHandler = ()=>{
  const cmd ={
   msg:'online'
  };
  console.log("##Data to send: "+JSON.stringify(cmd));
socket.emit('ping',cmd);
}

const updateHandler =(e)=>{
  const id = e.target.id;
  setTid(id);
  const ptid = pos[id].ptid;
  console.log("terminal id: "+id+" ptid: "+ptid);
  const cmd={
    ptid:ptid,
  }
  console.log("Data to send: "+JSON.stringify(cmd));
socket.emit('update',cmd);
}

//TODO: on start
//refresh();
const sockDisconnect =()=>{
  console.log("running socket disconnect here!!");
    //socket.disconnect();
}


useEffect(()=>{
  socket = io(Url);
  socket.open();
  socketClientInit();
    refreshInterval = setInterval(() => {
      socket.emit('ping',{msg:'online'});
    }, 15000);
  return (()=>{closeEvents()});
},[]);

const closeEvents=()=>{
  clearInterval(refreshInterval);
  socket.close();
}


     return(
       <div  className="pb-4">
       <Row>
       <Col  sm={12}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Force terminal Update</span></Card.Header>
       <Card.Body>
       <Form>

       <Form.Group>
       <Form.Label>Search</Form.Label>
       <Form.Control type="text" sm={2} placeholder="Search for terminal...." value={sdata} onChange={filterHandler}/>
       </Form.Group>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>No.</th>
            <th>Terminal ID</th>
            <th>Model</th>
            <th>Last Update</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
          <tbody>
          {pos.map((t,idx)=>(
            <tr>
             <td>{idx+1}</td>
             <td>{t.ptid}</td>
             <td>{t.model}</td>
             <td>{t.lastupdate}</td>
             <td className="text-info"><FeatherIcon icon="disc"/> {getStatus(t.status)}</td>
             <td><Button id={idx} onClick={updateHandler} variant="outline-danger">Push Update</Button></td>
            </tr>
          ))}
          </tbody>
        </Table>
       <Button type='submit' variant="info" size="sm" onClick={findHandler} >Find Terminals</Button>{} <Button variant="outline-info" size="sm" onClick={refreshHandler}>Refresh</Button>
       </Form>

       </Card.Body>
       </Card>
       </Col>
       </Row>
       </div>
     )
   }


  export default TerminalList;
