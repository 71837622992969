import React from 'react';
import {Form,Button,Card,Row,Col} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg} from './pop';
class MerchantServices extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        mid : this.props.mid,
        sale:false,
        cashback:false,
        balance:false,
        cash:false,
        deposit:false,
        setpin:false,
        activation:false,
        bills:false,
        mobilepayment:false,
        zipt:false,
        internal:false,
        id:0,
      }
    }

    componentDidMount() {
      console.log('Services Component DID MOUNT!')
      this.setState({mid:0,
        id:0,
        sale:false,
        cashback:false,
        balance:false,
        cash:false,
        deposit:false,
      setpin:false,
      activation:false,
      bills:false,
      mobilepayment:false,
      internal:false,
      zipt:false,});
   }

   async componentDidUpdate(prevProps, prevState) {
      console.log('Services Component DID UPDATE!')
      //this.setState({mid:this.props.mid});
      
      if(prevProps.services ==null){
          console.log('Previous Services are null!!')
          if(this.props.services != null){
          this.setState({mid:this.props.services.mid,
            id:this.props.services.id,
            sale:this.props.services.sale,
            cashback:this.props.services.cashback,
            balance:this.props.services.balance,
            cash:this.props.services.cash,
            deposit:this.props.services.deposit,
          setpin:this.props.services.setpin,
        activation:this.props.services.cardactivation,
        bills:this.props.services.billpayment,
        mobilepayment:this.props.services.mobilepayment,
        internal:this.props.services.internal,
        zipt:this.props.services.zipt,});
        const rand = await this.generateRandom();
          this.props.updatecards(rand);
    }
      }else{
          console.log('Previous Services Balance: '+prevProps.services.balance)
          if(this.props.services !==null){
          if(prevProps.services.random !== this.props.services.random){
         console.log("Inside services tab. Balance: "+this.props.services.balance);
         this.setState({mid:this.props.services.mid,
           id:this.props.services.id,
           sale:this.props.services.sale,
           cashback:this.props.services.cashback,
           balance:this.props.services.balance,
           cash:this.props.services.cash,
           deposit:this.props.services.deposit,
         setpin:this.props.services.setpin,
       activation:this.props.services.cardactivation,
     bills:this.props.services.billpayment,
     mobilepayment:this.props.services.mobilepayment,
     internal:this.props.services.internal,
   zipt:this.props.services.zipt,});
     console.log("Balance received Service: "+this.state.balance);
   }
      }
    }

      if(this.props.services!=null &&(prevProps.services !==null )){

}else{
//todo: handling record not found case
if(this.props.mid !== prevProps.mid && this.props.mid===0){
  console.log('Services Reset!!')
  this.setState({mid:0,
    id:0,
    sale:false,
    cashback:false,
    balance:false,
    cash:false,
    deposit:false,
  setpin:false,
  activation:false,
  bills:false,
  mobilepayment:false,
  internal:false,
  zipt:false,});
}
}


   }

   generateRandom = async ()=>{
    const min = 1;
      const max = 100;
      const random = min + (Math.random() * (max - min));
      return random;
  }


    saveserviceHandler =()=>{
      var url ;
      const {id} = this.state;
      console.log("current sid value: "+id);
      if(id>0){
        console.log("we are updating here please !!!");
      url =Url+'/updateservices'
      }else{
        console.log("We are adding new record here!!!");
        url =Url+'/addservices'
      }

      if(this.props.mid<=0 && sessionStorage.getItem('cmid') <= 0){
        ShowMsg('Please Select or Define Merchant First!!',false);
        return;
      }
      console.log('Doing merch services POST here!!! mid: '+this.props.mid+" sid: "+this.state.id);
      Axios.post(url,{
        id:this.state.id,
        mid: this.props.mid,
        sale:this.state.sale,
        cashback:this.state.cashback,
        balance:this.state.balance,
        cash:this.state.cash,
        deposit:this.state.deposit,
        cardactivation:this.state.activation,
        setpin:this.state.setpin,
        billpayment:this.state.bills,
        mobilepayment:this.state.mobilepayment,
        zipt:this.state.zipt,
        internal:this.state.internal
      }).then((res)=>{
        console.log('servicesResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('services ID Response received: '+res.data.id);
            this.setState({id:res.data.id});
            if(this.state.id>0)
          ShowMsg('Merchant Services updated Successfuly!!',true);
          else {
            ShowMsg('Merchant Services added Successfuly!!',true);
          }

          const rand = this.generateRandom();
          this.props.updatecards(rand);

        }else{
          ShowMsg('Saving record Failed!!',false);
        }
      });
    }

    clearHandler =()=>{
  console.log("clear button clicked!!!!!!!!!!");
  this.setState({
        sale:false,
        cashback:false,
        balance:false,
        cash:false,
        deposit:false,
      setpin:false,
    activation:false,
    bills:false,
    mobilepayment:false,
    internal:false,
    zipt:false,});
    }


    saleHandler=(e)=>{
      if(e.target.checked){
        this.setState({sale:true})
      }else{
        this.setState({sale:false})
      }
    }
    cashbackHandler=(e)=>{
      if(e.target.checked){
        this.setState({cashback:true})
      }else{
        this.setState({cashback:false})
      }
    }
    balanceHandler=(e)=>{
      if(e.target.checked){
        this.setState({balance:true})
      }else{
        this.setState({balance:false})
      }
    }
    cashHandler=(e)=>{
      if(e.target.checked){
        this.setState({cash:true})
      }else{
        this.setState({cash:false})
      }
    }
    depositHandler=(e)=>{
      if(e.target.checked){
        this.setState({deposit:true})
      }else{
        this.setState({deposit:false})
      }
    }
    cardactivationHandler=(e)=>{
      if(e.target.checked){
        this.setState({activation:true})
      }else{
        this.setState({activation:false})
      }
    }
    setpinHandler=(e)=>{
      if(e.target.checked){
        this.setState({setpin:true})
      }else{
        this.setState({setpin:false})
      }
    }
    billHandler=(e)=>{
      if(e.target.checked){
        this.setState({bills:true})
      }else{
        this.setState({bills:false})
      }
    }
    mobileHandler=(e)=>{
      if(e.target.checked){
        this.setState({mobilepayment:true})
      }else{
        this.setState({mobilepayment:false})
      }
    }
    ziptHandler=(e)=>{
      if(e.target.checked){
        this.setState({zipt:true})
      }else{
        this.setState({zipt:false})
      }
    }
    internalHandler=(e)=>{
      if(e.target.checked){
        this.setState({internal:true})
      }else{
        this.setState({internal:false})
      }
    }

    render(){
     return(
       <Card>
       <Card.Body>
       <Form>
       <Row>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" onClick={this.saleHandler} checked={this.state.sale} label="Purchase"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.cashbackHandler} checked={this.state.cashback}  label="Cashback"/>
       
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.balanceHandler} checked={this.state.balance}  label="Balance"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.depositHandler} checked={this.state.deposit}  label="Deposit"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.setpinHandler} checked={this.state.setpin}  label="Setpin"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.cardactivationHandler} checked={this.state.activation}  label="Card Activation"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.billHandler} checked={this.state.bills}  label="Bill Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.cashHandler} checked={this.state.cash} label="Cash Withdrawal"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox"  onClick={this.mobileHandler} checked={this.state.mobilepayment}  label="Mobile Payment"/>
       </Form.Group>
       </Col>
       </Row>
         <Button variant="outline-info" size="sm" onClick={this.saveserviceHandler}>SAVE</Button>{' '}
         <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       </Form>

       </Card.Body>
       </Card>
     );
    }
  }
export default MerchantServices;
