import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card,Row,Container,Col} from 'react-bootstrap'
 import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MerchantDetails from './merchantdetails';
import MerchantServices from './merchantservices';
import MerchantCards from './merchantcards';
import HostConfigurations from './hostconfigs';
import PromoConfigurations from './promoconfigs';
import OtherConfigurations from './otherconfigs'
import {MSGS} from './constants/msg';
import {EventAllowed} from './profilecfg';
import {ROLEOPS,USER_PROF,LoggedInUser} from './constants/constants';
class Merchants extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      tx:0,
      mid:0,
      sdata:null,
      crand:0,
    }
    }

    getMerchantId =(x)=>{
      this.setState({mid:x});
    }

    getServicesData=(data)=>{
      if(data !==null)
      console.log("random value: "+data.random);
      this.setState({sdata:data});
    }

    servicesUpdated =(rand)=>{
      this.setState({crand:rand});
    }

      render(){
        return(

          <Container className="mt-1 " fluid>
          <Row>
          <Col  sm={12}  className="text-center"><span className="App-header shadow-sm">MERCHANT CONFIGURATIONS</span></Col>
          </Row>
          <Row>
          <Col  sm={12}>
          <Card className=" pb-1">
          <Card.Body>
          <Tabs className="pb-3" forceRenderTabPanel defaultIndex={0}>
                <TabList>
                <Tab>Details</Tab>
                </TabList>
              <TabPanel>
                    <MerchantDetails />
              </TabPanel>
          </Tabs>

          </Card.Body>
          </Card>
          </Col>
          </Row>
          </Container>
        )
      }

    }

    export default Merchants;
