import React from 'react';
import {Form,Button,Card,Row,Col,CardGroup} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg} from './pop';
class HostConfigurations extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        id:0,
        mid : 0,
        gip:'',
        gport:'',
        gapn1:'',
        gapn2:'',
        tip:'',
        tport:'',
        wip:'',
        wport:'',
        wapn:'',
        tpdu:{
          id:0,
          tpduid:60,
          src:'',
          dest:'',
          cid:0,
        },
        ssl:false,

      }
    }
    componentDidUpdate(prevProps, prevState) {
      console.log("Configs component did update!!!!!!!! mid: "+this.props.mid+ ' prevprops.mid: '+prevProps.mid)
      if(this.props.mid !== prevProps.mid ){
        this.updateConfigs();
        window.onkeydown = (e)=>{
          if(e.ctrlKey === true){
            e.preventDefault();
          }
         }
       }else{
         console.log('Configs Criteria failing here!!!')

       }
    }

    clearConfigs=()=>{
      this.setState({
          id:0,
          gip:'',
          gport:'',
          gapn1:'',
          gapn2:'',
          tip:'',
          tport:'',
          wip:'',
          wport:'',
          wapn:'',
          tpdu:{
            id:0,
            istpdu:false,
            tpduid:60,
            src:'',
            dest:'',
            cid:0,
          },
          ssl:false,


        });
    }

    updateConfigs=()=>{
      var url =Url+'/gethostconfigs';
      var host = this.state;
      host.mid = this.props.mid;
      if(this.props.mid<=0){
          console.log('Hosts: mid not set!!!')
      //ShowMsg('Please Select or Define Merchant First!!',false);
      return;
      }
      console.log('Doing merch configs read POST here!!! mid: '+this.props.mid);
      Axios.post(url,host).then((res)=>{
        console.log('HostResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Hst setting data received OK!!');
            if(res.data.host===null){
            this.clearConfigs();
            }else{
              console.log("config.id: "+res.data.host.id+' tpdu.src: '+res.data.tpdu.src);
              var h = res.data.host;
              h.tpdu=res.data.tpdu;
              this.setState(h);
            }
        }else{
          console.log('Failed to retrieve host settings!!!')
        this.clearConfigs();
        }
      });
    }

    isvalidatedOk =()=>{
  if(this.state.gip.length===0 || this.state.gport.length===0 || this.state.tip.length===0 || this.state.tport.length===0 || this.state.wip.length===0 || this.state.wport.length===0)
      return false;
      if(this.state.tpdu.istpdu){
        if(this.state.tpdu.src.length===0 || this.state.tpdu.dest.length===0 || this.state.tpdu.tpduid<=0)
        return false;
      }
      return true;
    }

    sslHandler=(e)=>{
        if(e.target.checked){
      this.setState({ssl:true});
    }
      else {
        this.setState({ssl:false});
      }
    }
    tpduenableHandler=(e)=>{
      var {tpdu} = this.state;
      if(e.target.checked){
        tpdu.istpdu=true;
    }else{
      tpdu.istpdu=false;
    }
      this.setState({tpdu});
    }

    tpduidHandler=(e)=>{
      var tpdu = this.state.tpdu;
      tpdu.tpduid=e.target.value
      this.setState(tpdu);
    }

    tpdusrcHandler=(e)=>{
      var tpdu = this.state.tpdu;
      tpdu.src=e.target.value
      this.setState(tpdu);
    }


    tpdudestHandler=(e)=>{
      var tpdu = this.state.tpdu;
      tpdu.dest=e.target.value
      this.setState(tpdu);
    }



    gipHandler=(e)=>{
      this.setState({gip:e.target.value});
    }
    gportHandler=(e)=>{
      this.setState({gport:e.target.value});
    }
    gapn1Handler=(e)=>{
      this.setState({gapn1:e.target.value});
    }
    gapn2Handler=(e)=>{
      this.setState({gapn2:e.target.value});
    }

    tipHandler=(e)=>{
      this.setState({tip:e.target.value});
    }
    tportHandler=(e)=>{
      this.setState({tport:e.target.value});
    }

    wipHandler=(e)=>{
      this.setState({wip:e.target.value});
    }
    wportHandler=(e)=>{
      this.setState({wport:e.target.value});
    }
    wapnHandler=(e)=>{
      this.setState({wapn:e.target.value});
    }



    saveHandler =()=>{
      if(this.props.mid<=0){
        console.log("Comms here: mid<0");
      ShowMsg('Please Select or Define Merchant First!!',false);
      return;
      }
      if(!this.isvalidatedOk()){
        //todo add msgbox
        ShowMsg('Please fill all required fields!!',false);
        return;
      }
      var url =Url+'/addhostconfig';
      var host = this.state;
      host.mid = this.props.mid;
      if(this.props.hid>0){
        host.id = this.props.hid;
      }
      console.log('Doing merch cards read POST here!!! mid: '+this.props.mid);
      Axios.post(url,host).then((res)=>{
        console.log('HostResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Hst setting saved OK!!');
            ShowMsg('Host settings Saved Successfully!!',true);
        }else{
          console.log('Failed to save host settings!!!')
          ShowMsg('Failed to save host settings!!!',false);
        }
      });
    }

    render(){
     return(
       <Card>
       <Card.Body>
       <Form>
       <Row>
        <Col  sm={2}>
        <Form.Group>
        <Form.Check type="checkbox"  onClick={this.sslHandler} checked={this.state.ssl}  label="Use SSL"/>
       </Form.Group>
        <Form.Group>
        <Form.Check type="checkbox"  onClick={this.tpduenableHandler} checked={this.state.istpdu}  label="Use TPDU"/>
       </Form.Group>
        </Col>
        <Col  sm={2}>
        <Form.Group>
        <Form.Label>TPDU Header</Form.Label>
        <Form.Control type="text" disabled={!this.state.istpdu?'disabled':null} placeholder="" value={this.state.tpdu.tpduid} onChange={this.tpduidHandler}/>
       </Form.Group>
        </Col>
        <Col  sm={2}>
        <Form.Group>
        <Form.Label>Source</Form.Label>
        <Form.Control type="text" disabled={!this.state.istpdu?'disabled':null} placeholder="" value={this.state.tpdu.src} onChange={this.tpdusrcHandler}/>
       </Form.Group>
        </Col>
        <Col  sm={2}>
        <Form.Group>
        <Form.Label>Destination</Form.Label>
        <Form.Control type="text" disabled={!this.state.istpdu?'disabled':null} placeholder="" value={this.state.tpdu.dest} onChange={this.tpdudestHandler}/>
       </Form.Group>
        </Col>
       </Row>
       <CardGroup>
       <Card border='info'>
       <Card.Header variant='info'><h6>GPRS Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.gip} onChange={this.gipHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control type="number" placeholder="" value={this.state.gport} onChange={this.gportHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Sim1 APN</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.gapn1} onChange={this.gapn1Handler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Sim2 APN</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.gapn2} onChange={this.gapn2Handler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card  border='info'>
       <Card.Header><h6>WIFI Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control type="text"  value={this.state.wip} onChange={this.wipHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control type="number"  value={this.state.wport} onChange={this.wportHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>APN</Form.Label>
       <Form.Control type="text"  value={this.state.wapn} onChange={this.wapnHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card  border='info'>
       <Card.Header><h6>TCPIP Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control type="text" placeholder="" value={this.state.tip} onChange={this.tipHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control type="number" placeholder="" value={this.state.tport} onChange={this.tportHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       </CardGroup>
       <Button variant="info" size="sm" className='mt-2' onClick={this.saveHandler} >SAVE SETTINGS</Button>{' '}
       </Form>
       </Card.Body>
       </Card>
     )
   }

 }
export default HostConfigurations;
