import React, {Component} from 'react';
 import {Row, Button, Col, Form, Card, Container} from "react-bootstrap";
 import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

 import Home from './home'
 import Profile from './profile'
 import Contact from './contact'
 class TestApp extends Component{
     constructor(props) {
         super(props);

     }

     render() {
         return (
             <Container fluid>
                 <Row>
                     <Col>
                     <Card>
                     <Card.Body>
                         <Tabs className="pb-3" forceRenderTabPanel defaultIndex={2}>
                               <TabList>
                               <Tab>Home</Tab>
                               <Tab>Profile</Tab>
                               <Tab>Contact</Tab>
                               </TabList>
                             <TabPanel>
                                 <Home />
                             </TabPanel>
                             <TabPanel>
                                 <Profile />
                             </TabPanel>
                             <TabPanel >
                                 <Contact />
                             </TabPanel>
                         </Tabs>
                         </Card.Body>
                         </Card>
                     </Col>
                 </Row>
             </Container>
         );
     }
 }

 export default TestApp;
