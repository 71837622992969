import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Modal,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
import { Redirect } from "react-router";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

function ZinaraZwl(...props){
const fees = props.fees;
//console.log("fees structure: "+JSON.stringify(fees));
    const [lightvehicles,setLightVehicles] = useState('');
    const [heavyvehicles,setHeavyVehicles] = useState('');
    const [minibuses,setMiniBuses] = useState('');
    const [buses,setBuses] = useState('');
    const [trucks,setTrucks] = useState('');
    const [id,setId] = useState('0');
    const [firstrun,setFirstRun]=useStateWithCallbackLazy(true);
    const [gtariff,setGTariff] = useState('2');
    const [tariffchanged,setTariffChanged] = useState(false);
    const [toggleform,setToggleForm] = useState(false);
    const [gname,setGname] = useState('');
    const [gtname,setGtname] = useState('');
    const [tlist,setTlist] = useState([]);

    const gtrefresh = async (silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/gettgnames';
     const data ={};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            clearLiteHandler();
            setId('0');
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("refreshing Tariff group Name data: "+JSON.stringify(res.data.data));
            const f = res.data.data;
                setTlist(f,()=>{
                    //todo: populate on UI
                });
                
            
         }
     });
    }

    const refresh = async (silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/gettollfees';
     const data ={curr:'924',gtariff:gtariff};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            clearLiteHandler();
            setId('0');
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("refreshing zwg data: "+JSON.stringify(res.data.data));
            const f = res.data.data;
                setId(f.id);
                setLightVehicles((Number(f.lv)/100).toFixed(2));
                setHeavyVehicles((Number(f.hv)/100).toFixed(2));
                setMiniBuses((Number(f.mb)/100).toFixed(2));
                setBuses((Number(f.cb)/100).toFixed(2));
                setTrucks((Number(f.ht)/100).toFixed(2));
            setFirstRun(false,()=>{
                console.log("refresh Data: "+JSON.stringify());
                
            });
            
         }
     });
    }

    //todo: getting tolls here on scree render
    /*if(firstrun){
        refresh(true);
        setFirstRun(false);
    }*/

    useEffect(()=>{ 
        window.onkeydown = (e)=>{
            if(e.ctrlKey === true || e.metaKey === true){
              if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
              e.preventDefault();
            }
           }
        async function refreshdata(){
            console.log("getting toll fees here!!");
            await gtrefresh(true);
            await refresh(true);
            console.log("toll data: "+lightvehicles);
          };
          if(firstrun || tariffchanged===true){
            refreshdata();
            setTariffChanged(false);
          }
    },[tariffchanged]);

    const blurHandler = (e)=>{
        const num = e.target.value
        var disp = parseFloat(num);
        var val = Number(disp).toFixed(2);
        switch(e.target.id){
            case 'lv':
                setLightVehicles(val);
                break;
            case 'hv':
                setHeavyVehicles(val);
                break;
            case 'mb':
                setMiniBuses(val);
                break;
            case 'cb':
                setBuses(val);
                break;
            case 'ht':
                setTrucks(val);
                break;
                default:
                    break;  
        }
    }
   

    const feesHandler = (e)=>{
        const val = e.target.value;
        /*const num = e.target.value
        var disp = parseFloat(num);
        var val = Number(disp).toFixed(2);*/
        switch(e.target.id){
            case 'lv':
                setLightVehicles(val);
                break;
            case 'hv':
                setHeavyVehicles(""+val);
                break;
            case 'mb':
                setMiniBuses(val);
                break;
            case 'cb':
                setBuses(val);
                break;
            case 'ht':
                setTrucks(val);
                break;
                default:
                    break;

        }
    }
    const updatetollfees =()=>{
     if(lightvehicles.length===0 || heavyvehicles.length===0 || minibuses.length===0 || buses.length===0 || trucks.length===0){
         ShowMsg("Please Enter All Toll Fees, As Expected!!",false);
         return;
     }

    
     const idd = sessionStorage.getItem('ndiani');
     var url =Url+'/updatetollfees';
     const lv = (parseFloat(lightvehicles)*100)+"";
     const hv = (parseFloat(heavyvehicles)*100)+"";
     const mb = (parseFloat(minibuses)*100)+"";
     const cb = (parseFloat(buses)*100)+"";
     const ht = (parseFloat(trucks)*100)+"";

     const data ={id:id, curr:'924',gtariff:gtariff,lv:lv,hv:hv,mb:mb,cb:cb,ht:ht};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ok'){
             ShowMsg(res.data.pop,true);
         }else{
             ShowMsg(res.data.pop,false);
         }
     })
    }

 const clearLiteHandler  = (e)=>{
        setLightVehicles('');
        setHeavyVehicles('');
        setMiniBuses('');
        setBuses('');
        setTrucks('');
 }  

    

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnupdate':
          updatetollfees();
          break;
          case 'btnrefresh':
         refresh(false);
          break;
          default:
        }
    }

    const updateTariff = async (val)=>{
        setGTariff(val);
    }

    const gtHandler = (e)=>{
        var val = e.target.value;
        setGname(val);  
    }

    const groupTariffHandler = (e)=>{
        var val = e.target.value;
        console.log("groupTariff Value: "+val);
        setGTariff(val);
        setTariffChanged(true);
        clearLiteHandler();
        console.log("gtariff after set: "+gtariff);
        //await refresh(true);
        
      }

      const toggleFormHandler =()=>{
            setToggleForm(!toggleform);
      }
   
    return(
        <Container className="mt-2" fluid>
    <Row className="pt-2 ">
    <Col  sm={8}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <Form>
    <Form.Group >
    <Form.Label>Zinara Tariff Groups</Form.Label>
    <Form.Select id='gtariff'  size="sm" onChange={groupTariffHandler} value={gtariff} custom >
    <option>Select Tariff Group</option>
    {           
    tlist.map((opt)=>(
    <option value={opt.id}>{opt.name}</option>
    ))
    }
    </Form.Select>
    </Form.Group>
    <Form.Group>
    <Form.Label>Light Vehicles</Form.Label>
    <Form.Control id='lv' autoComplete={'nope3'} type="number" placeholder="Light Vehicles..." value={lightvehicles} onBlur={blurHandler} onChange={feesHandler}/>
    </Form.Group>  
    <Form.Group>
    <Form.Label>Mini Buses</Form.Label>
    <Form.Control id='mb' autoComplete={'nope3'} type="number" placeholder="Mini Buses..." value={minibuses} onBlur={blurHandler} onChange={feesHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Buses</Form.Label>
    <Form.Control id='cb' autoComplete={'nope3'} type="number" placeholder="Buses..." value={buses} onBlur={blurHandler} onChange={feesHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Heavy Vehicles</Form.Label>
    <Form.Control id='hv' autoComplete={'nope3'} type="number" placeholder="Heavy Vehicles..." value={heavyvehicles} onBlur={blurHandler} onChange={feesHandler}/>
    </Form.Group>
    <Form.Group>
    <Form.Label>Haulage Trucks</Form.Label>
    <Form.Control id='ht' autoComplete={'nope3'} type="number" placeholder="Haulage Trucks..." value={trucks} onBlur={blurHandler} onChange={feesHandler}/>
    </Form.Group>
    </Form>
    </Card.Body>
    <Card.Footer>
      <Button id='btnupdate' variant="info" onClick={btnHandler} size="sm" >UPDATE TOLL FEES</Button>{' '}
      <Button id='btnrefresh' variant="outline-info" size="sm" onClick={btnHandler} >REFRESH</Button>{' '}
    </Card.Footer>
    </Card>
    </Col>
    </Row>
    <Modal  show={toggleform}
       backdrop="static"
       keyboard={false}>
    <Modal.Header id='zinarazwl'  closeButton onClick={toggleFormHandler}>
      <Modal.Title >Zinara Tariff GroupName List</Modal.Title>
      </Modal.Header> 
      <Modal.Body className=" pb-1 pt-2">
      <Row className="pt-2 ">
    <Col  sm={8} >
    <Form.Group>
    <Form.Label>Zinara Group Tariff Name</Form.Label>
    <Form.Control id='gt' autoComplete={'nope3'} type="text" placeholder="Tariff Name..." value={gname} onBlur={blurHandler} onChange={gtHandler}/>
    </Form.Group>
    </Col>
    <Col  sm={4}className=" pb-1 pt-2 sform">

    </Col>
    </Row>
        
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleFormHandler} >CLOSE</Button>{' '}
      </Modal.Footer>   
</Modal>
    </Container>
    )


}
export default ZinaraZwl;