import React, {useState,useEffect,useRef} from 'react';
import {Tabs,Tab,Modal,Form,Button,InputGroup,FormControl,Card,Row,Container,Col,ListGroup} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import {ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function Cards(...props){
    const [cardGroupList,setCardGroupList]= useState([]);
    const cardsRef = useRef(cardGroupList);
    const [showHide,setShowHide] = useState(false);
    const [newcardname,setNewcardName] = useState('');
    const [binRange,setBinRange] = useState('');
    const [cardid,setCardId] = useState(0);
    useEffect(()=>{
        
        if(cardGroupList.length === 0){
            console.log('Cards scheme length: '+ cardGroupList.length); 
        getCards();
        }else{
            console.log('Else Cards scheme length: '+ cardGroupList.length);
        }
      },[cardGroupList]);
    const cardGroupInfoHandler = (e)=>{
        //todo: to add code to pop up cardgroup info
        e.preventDefault();
    var id = e.target.value;
    const card = cardGroupList[id];
    setCardId(card.id);
    setNewcardName(card.name);
    setBinRange(card.binrange);
    toggleModal();
    }

    const toggleModal =()=>{
        setShowHide(!showHide);
    }

    const addcardHandler =()=>{
        setNewcardName('');
        setBinRange('');
        setShowHide(true);
        setCardId(0);
    }
    const newCardNameHandler =(e)=>{
        setNewcardName(e.target.value);
    }
    const getCards = ()=>{
        console.log("calling get cards here!!!");
        var url =Url+'/getcardgroups'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                setCardGroupList(res.data.cards);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }

    const binRangeHandler =(e)=>{
        setBinRange(e.target.value);
    }

    const addRecord =()=>{
        if(newcardname.length===0){
            ShowMsg('Please enter Card Group Name!!',false);
            return;
          }
          if(binRange.length===0){
            ShowMsg('Please enter Bin Ranges that are comma seperated!!',false);
            return;
          }
       
          
       
          var url =Url+'/addcardgroup'
          Axios.post(url,{
              id: cardid,
            name:newcardname,
            binrange: binRange 
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                console.log('Cards ID Response received: '+res.data.id);
                if(res.data.id>0){
                    if(cardid>0)
                    ShowMsg('Card Group Updated Successfuly!!',true);
                    else
                    ShowMsg('Card Group Added Successfuly!!',true);
                }
                setCardGroupList(res.data.cards);
              
            }else{
              ShowMsg(res.data.pop,false);
              console.log("Received Cards list: "+JSON.stringify(res.data.cards));
              setCardGroupList(res.data.cards);
            }
          });
    }
return( 
<Container className='pb-4' fluid>
<Row>
<Col sm={6}>
<Card>
       <Card.Header><span className="App-header shadow-sm">Card Group Configurations</span></Card.Header>
       <Card.Body>
       <InputGroup className="mb-3">
        <FormControl  
        placeholder="Card Group Name..."
        aria-label="Card Group Name..."
        aria-describedby="btnfindcardgrp"
        />
        <Button  variant="outline-info" id="btnfindcardgrp" onClick={()=>ShowMsg('Search Option Not Yet Implemented!!',false)}>
        Find Card Group
        </Button>
        </InputGroup>
        <React.Fragment className="sform">
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Configured Card Group List</h6></ListGroup.Item>
         {cardGroupList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>cardGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={addcardHandler}>NEW CARD GROUP</Button>{' '}
       </Card.Footer>
</Card>
</Col>
<Col sm={6}>
</Col>
</Row>
<Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       size="md">
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >New Card Groups</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group>
      <Form.Label>Card Group Name</Form.Label>
      <Form.Control type="text" placeholder="Card Group Name...." value={newcardname} onChange={newCardNameHandler}/>
      </Form.Group>
      </Form>
      <Form.Group>
      <Form.Label>BIN Range</Form.Label>
      <Form.Control type="text" placeholder="BIN Range: (400000-49999,600000-699999,....)" value={binRange} onChange={binRangeHandler}/>
      </Form.Group>
      
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addRecord} >SAVE RECORD</Button>

      </Modal.Footer>
      </Modal>
</Container>
);
}

export default Cards;