import React, {useState,useEffect,useRef} from 'react';
import {Tabs,Tab,Modal,Form,Button,InputGroup,FormControl,Card,Row,Container,Col,ListGroup,CardGroup} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import {ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function CommsSettings(...props){
    const [commsGroupList,setCommsGroupList]= useState([]);
    const commsRef = useRef(commsGroupList);
    const [showHide,setShowHide] = useState(false);
    const [commsName,setCommsName] = useState('');
    const [binRange,setBinRange] = useState('');
    const [commsid,setCommsId] = useState(0);
    const [comms,setComms]=useState({id:0,name:'',gip:'',gport:'',gapn1:'',gapn2:'',tip:'',tport:'',wip:'',wport:'',wapn:'',ssl:false});
    const [checked,setChecked]=useState(false);
    useEffect(()=>{
      window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
        if(checked===false){
            console.log('Cards scheme length: '+ commsGroupList.length); 
            getComms();
        }else{
            console.log('Else Cards scheme length: '+ commsGroupList.length);
        }
      },[commsGroupList,checked]);
    const commsGroupInfoHandler = (e)=>{
        //todo: to add code to pop up cardgroup info
        e.preventDefault();
        setComms({id:0,name:'',gip:'',gport:'',gapn1:'',gapn2:'',tip:'',tport:'',wip:'',wport:'',wapn:'',ssl:false});
    var id = e.target.value;
    const c = commsGroupList[id];
    setComms(c)
    //setCommsId(comm.id);
    //setCommsName(comm.name);
    //setBinRange(card.binrange);
    toggleModal();
    }

    const toggleModal =()=>{
        setShowHide(!showHide);
    }

    const addcommsHandler =()=>{
      setComms({id:0,name:'',gip:'',gport:'',gapn1:'',gapn2:'',tip:'',tport:'',wip:'',wport:'',wapn:'',ssl:false});
        setCommsName('');
        //setBinRange('');
        setShowHide(true);
        setCommsId(0);
    }
    const newComssGroupNameHandler =(e)=>{
        setCommsName(e.target.value);
    }
    const getComms = ()=>{
        console.log("calling get cards here!!!");
        var url =Url+'/getcommsgrps'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                setCommsGroupList(res.data.comms);
            }else{
              ShowMsg(res.data.pop,false);          
            }
            setChecked(true);
          });
    }

   

    const addRecord =()=>{
      var ret =false;
        if(comms.name.length===0){
            ShowMsg('Please enter Comms Group Name!!',false);
            return;
          }
         
          Object.keys(comms).forEach((key)=>{
              if(comms[key]==='' || comms[key]===null){
                ShowMsg('Please ensure that all fields are filled up!!',false);
                ret=true;
                return; 
              }
          })
       
          if(ret === true)
          return;
       
          var url =Url+'/addcommsgrp'
          console.log("comms Data tosend: "+JSON.stringify(comms));
          Axios.post(url,
              comms
          ).then((res)=>{
            console.log('CommsResponse received: '+res.data.msg);
            console.log("Received Comms list: "+JSON.stringify(res.data.comms));
            if(res.data.msg==='ok'){
                //console.log('Comms ID Response received: '+res.data.id);
              
                    if(comms.id>0)
                    ShowMsg('Comms Group Updated Successfuly!!',true);
                    else
                    ShowMsg('Comms Group Added Successfuly!!',true);
          
                setCommsGroupList(res.data.comms);
              
            }else{
              ShowMsg(res.data.pop,false);
              
              setCommsGroupList(res.data.comms);
            }
            toggleModal();
          });
    }
  const commsdataHandler = (e)=>{
    var id = e.target.id;
    var c = comms;
    var val = e.target.value;
    console.log("entered value: "+val);
    switch(id){
      case 'name':
      setComms({...comms,name:val});
      break;
      case 'gip':
      setComms({...comms,gip:val});
      break;
      case 'gport':
      setComms({...comms,gport:val});
      break;
      case 'gapn1':
      setComms({...comms,gapn1:val});
      break;
      case 'gapn2':
      setComms({...comms,gapn2:val});
      break;
      case 'tip':
      setComms({...comms,tip:val});
      break;
      case 'tport':
      setComms({...comms,tport:val});
      break;
      case 'wip':
      c.wip+=val;
      setComms({...comms,wip:val});
      break;
      case 'wport':
      setComms({...comms,wport:val});
      break;
      case 'wapn':
      setComms({...comms,wapn:val});
      break;
      case 'ssl':
        if(e.target.checked)
        setComms({...comms,ssl:true});
        else
        setComms({...comms,ssl:false});
      break;
      default:
    }
   
  }

  const saveHandler =()=>{

  }

return( 
<Container className='pb-4' fluid>
<Row>
<Col sm={6}>
<Card>
       <Card.Header><span className="App-header shadow-sm">Comms Configurations</span></Card.Header>
       <Card.Body>
       <InputGroup className="mb-3">
        <FormControl  
        placeholder="Comms Group Name..."
        aria-label="Comms Group Name..."
        aria-describedby="btnfindcommsgrp"
        />
        <Button  variant="outline-info" id="btnfindcommsgrp" onClick={()=>ShowMsg('Search Option Not Yet Implemented!!',false)}>
        Find Comms Group
        </Button>
        </InputGroup>
        <React.Fragment >
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Configured Comms Group List</h6></ListGroup.Item>
         {commsGroupList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>commsGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={addcommsHandler}>NEW COMMS GROUP</Button>{' '}
       </Card.Footer>
</Card>
</Col>
<Col sm={6}>
</Col>
</Row>
<Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       dialogClassName={"modal-70w"}>
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >New Host Configurations Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Card>
       <Card.Body>
       <Form>
         <Row>
           <Col>
           <Form.Group>
          <Form.Label>Comms Group Name</Form.Label>
          <Form.Control id='name'  type="text" placeholder="Promo Group Name" value={comms.name} onChange={commsdataHandler}/>
          </Form.Group>
           </Col>
        
         </Row>
       <Row>
        
        <Col  sm={2}>
        <Form.Group>
        <Form.Check id='ssl' type="checkbox"  onClick={commsdataHandler} checked={comms.ssl}  label="Use SSL"/>
       </Form.Group>
        </Col>
       </Row>
       <CardGroup>
       <Card border='info'>
       <Card.Header variant='info'><h6>GPRS Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control id='gip' type="text" placeholder="" value={comms.gip} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control id='gport' type="number" placeholder="" value={comms.gport} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Sim1 APN</Form.Label>
       <Form.Control id='gapn1' type="text" placeholder="" value={comms.gapn1} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Sim2 APN</Form.Label>
       <Form.Control id='gapn2' type="text" placeholder="" value={comms.gapn2} onChange={commsdataHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card  border='info'>
       <Card.Header><h6>WIFI Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control id='wip' type="text"  value={comms.wip} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control id='wport' type="number"  value={comms.wport} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>APN</Form.Label>
       <Form.Control id='wapn' type="text"  value={comms.wapn} onChange={commsdataHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card  border='info'>
       <Card.Header><h6>TCPIP Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Host IP</Form.Label>
       <Form.Control id='tip' type="text" placeholder="" value={comms.tip} onChange={commsdataHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Host Port</Form.Label>
       <Form.Control id='tport' type="number" placeholder="" value={comms.tport} onChange={commsdataHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       </CardGroup>
       </Form>
       </Card.Body>
       </Card>
      
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addRecord} >SAVE RECORD</Button>

      </Modal.Footer>
      </Modal>
</Container>
);
}

export default CommsSettings;