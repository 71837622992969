import React from 'react';
import {Form,Button,InputGroup,FormControl,Card,Row,Col,ListGroup,Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import Axios from 'axios'
import {Url} from './url';
import {ShowMsg} from './pop';
class MerchantCards extends React.Component{
  constructor(props){
    super(props);
    this.state = {
       cid:0,
        mid : 0,
        sale:false,
        cashback:false,
        balance:false,
        mobilepayment:false,
        cash:false,
        deposit:false,
        setpin:false,
        activation:false,
        bills:false,
        zipt:false,
        internal:false,
        acard:{
          id:0,
          name:'',
          startrange:'',
          endrange:'',
          sale:false,
          cashback:false,
          balance:false,
          cash:false,
          deposit:false,
          setpin:false,
          cardactivation:false,
          billpayment:false,
          mobilepayment:false,
          zipt:false,
          internal:false,
          mid:0,
        },
        apin:{
          id:0,
          sale:false,
          balance:false,
          cash:false,
          deposit:false,
          setpin:true,
          activation:true,
          billpayment:false,
          zipt:false,
          internal:false,
          cid:0,
        },
        cards:[],
        pins:[],
        cardlist:[],
        addcard:false,
        showHide:false,
        cardname:'',
        binrange:'',
        sbin:'',
        ebin:'',
        cardtitle:'No Card Group Selected',
        pin:{
          id:0,
          sale:false,
          balance:false,
          cash:false,
          deposit:false,
          setpin:true,
          activation:true,
          billpayment:false,
          zipt:false,
          internal:false,
          cid:0,
        },
        disable:{
          sale:false,
          cashback:false,
          balance:false,
          cash:false,
          deposit:false,
          setpin:false,
          activation:false,
          billpayment:false,
          mobilepayment:false,
          zipt:false,
          internal:false,
        }
      }
    }

    async componentDidUpdate(prevProps, prevState) {
      console.log("Cards component did update!!!!!!!! mid: "+this.props.mid+ ' prevprops.mid: '+prevProps.mid+ 'Pin.sale: '+this.state.pin.sale)
      //this.updateCards();
      if(this.props.mid !== prevProps.mid || this.props.rand !== prevProps.rand ){
        window.onkeydown = (e)=>{
          if(e.ctrlKey === true){
            e.preventDefault();
          }
         }
        this.updateCards();
        await this.getCards();
       }else{
         console.log('Cards Criteria failing here!!!')

       }
    }
    cardGroupInfoHandler = async (e)=>{
      //todo: get card groups here
      e.preventDefault();
    var id = e.target.value;
    await this.getCards();
    const card = this.state.cardlist[id];
    this.toggleModal();
    this.setState({newcardname:card.name,binrange:card.binrange});
    this.addrecord();
    }
    getCards = async ()=>{
      console.log("calling get cards here!!!");
      var url =Url+'/getcardgroups'
        Axios.post(url,{
           
        }).then((res)=>{
          console.log('CardsResponse received: '+res.data.msg);
          if(res.data.msg==='ok'){
              //console.log('Cards ID Response received: '+res.data.id);
              this.setState({cardlist:res.data.cards});
          }else{
            ShowMsg(res.data.pop,false);          
          }
        });
  }
    updateCards =()=>{
      var url =Url+'/getcards'
      console.log('Doing merch cards read POST here!!! mid: '+this.props.mid);
      Axios.post(url,{
        mid:this.props.mid,
      }).then((res)=>{
        console.log('CardslistResponse received: '+res.data.msg);
        if(res.data.msg==='ok'){
            console.log('Cards list received: size '+res.data.cards.length+' pins list size: '+res.data.pins.length);
            if(res.data.cards.length===0){
              var card={
              acard:{},
              cards:[],
              cardlist:[],
              pins:[],
              addcard:false,
              showHide:false,
              cardname:'',
              newcardname:'',
              binrange:'',
              sbin:'',
              ebin:'',
              apin:{
                id:0,
                sale:false,
                balance:false,
                cash:false,
                deposit:false,
                setpin:true,
                activation:true,
                billpayment:false,
                zipt:false,
                internal:false,
                cid:0,
              },
              disable:{
                sale:false,
                cashback:false,
                balance:false,
                cash:false,
                deposit:false,
                setpin:false,
                activation:false,
                billpayment:false,
                mobilepayment:false,
                zipt:false,
                internal:false,
              },
              cardtitle:'No Card Group Selected'
            };
              this.setState(card);
            }else{
              this.setState({cards:res.data.cards,pins:res.data.pins,disable:res.data.disable});
            }

        }else{
          console.log('Failed to get card group list!!!')
        }
      });
    }


    cardsmenuHandler=(e)=>{
    e.preventDefault();
    var id = e.target.value;
    console.log("Selected Cards ID: "+id);
    /*if(id>0)
     id -=1;*/
    //  var p;
    //  var {acard,apin,cards,pins}=this.state;
    //  console.log("pins List details: "+JSON.stringify(pins));
    //  const c = cards[id];
    //  if(pins.length> 0 && (pins !== null || pins !== 'undefined')){
    //  p = pins[id];
    //  }else{
    //  p= apin;
    //  p.cid = c.id;
    //  }

     //  var title=c.name+': ('+c.binrange+')';
    // console.log('Service: sale: '+c.sale);
    //
    //  console.log('Pin: sale: '+p.sale+" p.cid: "+p.cid);
    // this.setState({acard:c,apin:p,cardtitle:title});
    //  this.setState(prevState=>({
    //    ...prevState,
    //    acard:c,
    //    apin:p,
    //    cardtitle:title
    //  }))


    var url =Url+'/getcard'
    console.log('Doing merch card read POST here!!! cid: '+id);
    Axios.post(url,{
      id: id,
      mid:this.props.mid,
    }).then((res)=>{
      console.log('CardResponse received: '+res.data.msg);
      if(res.data.msg==='ok'){
        console.log("Selected card data: "+ JSON.stringify(res.data.card));
        const pin =  res.data.pin;
        const disable = res.data.disable;
        console.log("cardspin->setpin: "+ pin.setpin);
        const card = res.data.card;
        var title=card.name+': ('+card.binrange+')';
      // this.setState({cid:card.id,cardname:card.name,sbin:card.startrange,ebin:card.endrange,
      //   sale:card.sale,balance:card.balance,cash:card.cash,deposit:card.deposit,
      //   cardactivation:card.activation,setpin:card.setpin,bills:card.billpayment,
      // zipt:card.zipt,internal:card.internal,cardtitle:title,pin:pin,disable:disable});
      this.setState({acard:card,apin:pin,cardtitle:title,disable:disable});
      }else{
        ShowMsg('Failed to read card group details!!',false);
      }
    });
    }

    addcardHandler=(e)=>{
      e.preventDefault();
      if(this.props.mid<=0){
        this.setState({cid:0});
        console.log("Cards: mid not right here: "+this.props.mid);
       ShowMsg('Please Select or Define Merchant First!!',false);
       return;
     }
     
     this.toggleModal();
    }

    toggleModal=()=>{
      console.log("Card list size: "+this.state.cardlist.length);
  this.setState({showHide: !this.state.showHide});
  }

  cardnameHandler =(e)=>{
      this.setState({cardname:e.target.value})
  }

  newcardnameHandler =(e)=>{
      this.setState({newcardname:e.target.value})
  }

  sbinHandler =(e)=>{
      this.setState({sbin:e.target.value})
  }
  ebinHandler =(e)=>{
      this.setState({ebin:e.target.value})
  }

  addrecord =()=>{
   if(this.state.newcardname.length===0){
     ShowMsg('Please enter Card Name!!',false);
     return;
   }
   if(this.state.binrange.length===0){
     ShowMsg('Please enter Bin Range!!',false);
     return;
   }

   if(this.props.mid < 1){
     ShowMsg('Please Select or Define Merchant First!!',false);
     return;
   }

   var url =Url+'/addcard'
   console.log('Doing merch cards POST here!!! mid: '+this.props.mid);
   Axios.post(url,{
     mid: this.props.mid,
     name:this.state.newcardname,
     binrange:this.state.binrange,
     startrange: this.state.sbin,
     endrange: this.state.ebin,
     sale:false,
     cashback:false,
     balance:false,
     cash:false,
     deposit:false,
     cardactivation:false,
     setpin:false,
     billpayment:false,
     mobilepayment:false,
     zipt:false,
     internal:false,
     pin:{
       sale:false,
       balance:false,
       cash:false,
       deposit:false,
       cardactivation:true,
       setpin:true,
       billpayment:false,
       zipt:false,
       internal:false,
     }
   }).then((res)=>{
     console.log('CardsResponse received: '+res.data.msg);
     if(res.data.msg==='ok'){
         console.log('Cards ID Response received: '+res.data.id);
         this.setState({cards:res.data.cards,pins:res.data.pins});
         if(res.data.id>0)
       ShowMsg('Merchant Cards updated Successfuly!!',true);
     }else{
       ShowMsg(res.data.error,false);
     }
   });
  }

  updaterecord =()=>{

   if(this.props.mid<=0){
       console.log('card: mid not set!!!')
    ShowMsg('Please Select or Define Merchant First!!',false);
    return;
  }

   var url =Url+'/updatecard'
   console.log('Doing merch card update POST here!!! mid: '+this.props.mid);
   Axios.post(url,{
     card:this.state.acard,
     pin: this.state.apin,
   }).then((res)=>{
     console.log('CardsUpdate Response received: '+res.data.msg);
     if(res.data.msg==='ok'){
         console.log('Cards ID Response received: '+res.data.id);
       ShowMsg('Merchant Cards updated Successfuly!!',true);
     }else{
       ShowMsg(res.data.error,false);
     }
   });
  }


  saleHandler=(e)=>{
    var {acard}= this.state;
    if(e.target.checked){
      acard.sale=true;
    }else{
      acard.sale=false;
    }
    this.setState({acard});
  }

  cashbackHandler=(e)=>{
    var {acard}= this.state;
  if(e.target.checked){
    acard.cashback=true
  }else{
    acard.cashback=false
  }
  this.setState({acard});
}
  balanceHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.balance=true
    }else{
      acard.balance=false;
    }
    this.setState({acard});
  }
  cashHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.cash=true
    }else{
      acard.cash=false
    }
    this.setState({acard});
  }
  depositHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.deposit=true
    }else{
      acard.deposit=false
    }
    this.setState({acard});
  }
  cardactivationHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.cardactivation=true;
    }else{
      acard.cardactivation=false;
    }
    this.setState({acard});
  }
  setpinHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.setpin=true;
    }else{
      acard.setpin=false;
    }
    this.setState({acard});
  }
  billHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.billpayment=true;
    }else{
      acard.billpayment=false;
    }
    this.setState({acard});
  }
  mobileHandler=(e)=>{
    var {acard}= this.state;
  if(e.target.checked){
    acard.mobilepayment=true;
  }else{
    acard.mobilepayment=false;
  }
  this.setState({acard});
}
  ziptHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
      acard.zipt=true;
    }else{
      acard.zipt=false;
    }
    this.setState({acard});
  }
  internalHandler=(e)=>{
      var {acard}= this.state;
    if(e.target.checked){
    acard.internal=true;
    }else{
      acard.internal=false;
    }
    this.setState({acard});
  }



  //PIN configs

  salepinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.sale=true;
    }else{
      apin.sale=false;
    }
    this.setState(apin);
  }
  balancepinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.balance=true;
    }else{
      apin.balance=false;
    }
    this.setState({apin});
  }
  cashpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.cash=true;
    }else{
      apin.cash=false;
    }
    this.setState({apin});
  }
  depositpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
    apin.deposit=true;
    }else{
      apin.deposit=false
    }
    this.setState({apin});
  }
  cardactivationpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.activation=true;
    }else{
      apin.activation=false;
    }
    this.setState({apin});
  }
  setpinpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.setpin=true;
    }else{
      apin.setpin=false;
    }
    this.setState({apin});
  }
  billpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.billpayment=true;
    }else{
      apin.billpayment=false;
    }
    this.setState({apin});
  }
  ziptpinHandler=(e)=>{
    var {apin} = this.state;
    if(e.target.checked){
      apin.zipt=true;
    }else{
      apin.zipt=false;
    }
    this.setState({apin});
  }
  internalpinHandler=(e)=>{
    var {apin} = this.state.pin;
    if(e.target.checked){
      apin.internal=true;
    }else{
      apin.internal=false;
    }
    this.setState({apin});
  }

    render(){
     return(

       <div>
       <Card>
       <Card.Body>
       <Form>
       <Row>
       <Col  sm={4}>
       <React.Fragment>
       <ListGroup>
       <ListGroup.Item action  onClick={(e)=>this.addcardHandler(e)} variant="dark"><h6>[+]Card Groups</h6></ListGroup.Item>
       {this.state.cards.map((item,idx)=>(
         <ListGroup.Item action value={item.id} key={item.id} onClick={(e)=>this.cardsmenuHandler(e)} variant='info'>
         {item.name}
         </ListGroup.Item>
       ))}
       </ListGroup>
       </React.Fragment>
       </Col>
       <Col sm={8}>
       <Row>
       <Col>
       <Form.Group>
       <ListGroup>
       <ListGroup.Item variant="dark"><h6>{this.state.cardtitle} </h6></ListGroup.Item>
       </ListGroup>
       </Form.Group>
       </Col>
       </Row>
       <Row>
       <Col>
       <Form.Group>
       <span><h6>Card Sevices Allowed</h6></span>
       </Form.Group>
       </Col>
       </Row>
       <Row>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox"  disabled={!this.state.disable.sale?'disabled':null}  onClick={this.saleHandler} checked={this.state.acard.sale} label="Purchase"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.cashback?'disabled':null} onClick={this.cashbackHandler} checked={this.state.acard.cashback} label="Cashback"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.balance?'disabled':null} onClick={this.balanceHandler} checked={this.state.acard.balance} label="Balance"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.deposit?'disabled':null} onClick={this.depositHandler} checked={this.state.acard.deposit} label="Deposit"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.setpin?'disabled':null} onClick={this.setpinHandler} checked={this.state.acard.setpin} label="Setpin"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.cash?'disabled':null} onClick={this.cashHandler} checked={this.state.acard.activation} label="Cash Withdrawal"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.billpayment?'disabled':null} onClick={this.billHandler} checked={this.state.acard.billpayment} label="Bill Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.internal?'disabled':null} onClick={this.internalHandler} checked={this.state.acard.internal} label="Internal Transfer"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.mobilepayment?'disabled':null} onClick={this.mobileHandler} checked={this.state.acard.mobilepayment} label="Mobile Payment"/>
       </Form.Group>
       </Col>
       </Row>
       <Row>
       <Col>
       <Form.Group>
       <span><h6>PIN Configurations</h6></span>
       </Form.Group>
       </Col>
       </Row>
       <Row>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.sale?'disabled':null} onClick={this.salepinHandler} checked={this.state.apin.sale} label="Purchase"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.balance?'disabled':null} onClick={this.balancepinHandler} checked={this.state.apin.balance} label="Balance"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.cash?'disabled':null} onClick={this.cashpinHandler} checked={this.state.apin.cash} label="Cash Withdrawal"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.deposit?'disabled':null} onClick={this.depositpinHandler} checked={this.state.apin.deposit} label="Deposit"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.setpin?'disabled':null} checked={this.state.apin.setpin} label="Setpin"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.cardactivation?'disabled':null} checked={this.state.apin.activation} label="Card Activation"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.billpayment?'disabled':null} onClick={this.billpinHandler} checked={this.state.apin.billpayment} label="Bill Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.internal?'disabled':null} onClick={this.internalpinHandler} checked={this.state.apin.internal} label="Internal Transfer"/>
       </Form.Group>
       <Form.Group>
       <Form.Check type="checkbox" disabled={!this.state.disable.zipt?'disabled':null} onClick={this.ziptpinHandler} checked={this.state.apin.zipt} label="Zipt"/>
       </Form.Group>
       </Col>
       </Row>
       </Col>
       </Row>
       <Button variant="info" size="sm" className='mt-3'  onClick={this.updaterecord} >SAVE CARD GROUP</Button>{' '}
       </Form>
       </Card.Body>
       </Card>

       <Modal
       show={this.state.showHide}
       backdrop="static"
       keyboard={false}
       size="md">
      <Modal.Header  closeButton onClick={this.toggleModal}>
      <Modal.Title>Add Card Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Card>
       <Card.Header><span className="App-header shadow-sm">Card Group Configurations</span></Card.Header>
       <Card.Body>
       <InputGroup className="mb-3">
        <FormControl  
        placeholder="Card Group Name..."
        aria-label="Card Group Name..."
        aria-describedby="btnfindcardgrp"
        />
        <Button  variant="outline-info" id="btnfindcardgrp">
        Find Card Group
        </Button>
        </InputGroup>
        <React.Fragment className="sform">
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Configured Card Group List</h6></ListGroup.Item>
         {this.state.cardlist.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>this.cardGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
       </Card.Body>
       <Card.Footer className="text-muted">
       
       </Card.Footer>
</Card>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={this.getCards} >REFRESH</Button>{' '}
      <Button variant="info" size="sm" onClick={this.toggleModal} >CLOSE</Button>{' '}
      </Modal.Footer>
       </Modal>

      
      </div>
     );
   }

  }
  export default MerchantCards;
