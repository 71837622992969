import React, {Component} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';


class Home extends  Component{
  
    render() {
        return (
            <div>
                This is my Home Page
            </div>
        );
    }

}
export default Home;
