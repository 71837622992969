import React, {Component} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import MerchantDetails from './merchantdetails';

class Profile extends  Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <div>
                This is my Profile Page
                < MerchantDetails />
            </div>
        );
    }

}
export default Profile;
