import React, {useState,useEffect,useRef} from 'react';
import {Tabs,Tab,Modal,Form,Button,InputGroup,FormControl,Card,Row,Container,Col,ListGroup,CardGroup} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import {ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function PromoSettings(...props){
    const [cardGroupList,setCardGroupList]= useState([]);
    const [promoGroupList,setPromoGroupList]= useState([]);
    const cardsRef = useRef(cardGroupList);
    const [showHide,setShowHide] = useState(false);
    const [newcardname,setNewcardName] = useState('');
    const [binRange,setBinRange] = useState('');
    const [cardid,setCardId] = useState(0);
    const [promoid,setPromoId] = useState(0);
    const [promo0,setPromo0] = useState('');
    const [promo1,setPromo1] = useState('');
    const [promo2,setPromo2] = useState('');
    const [promo3,setPromo3] = useState('');
    const [promo4,setPromo4] = useState('');
    const [grpname,setGrpName] = useState('');
    const [checked,setChecked]=useState(false);
    useEffect(()=>{
      window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
        if(checked===false){
            console.log('PromoGrps length: '+ promoGroupList.length); 
        getPromogroups();
        setChecked(true);
        }else{
            console.log('Else PromoGrp length: '+ promoGroupList.length);
        }
      },[promoGroupList]);
    const PromoGroupInfoHandler = (e)=>{
        //todo: to add code to pop up cardgroup info
        e.preventDefault();
    var id = e.target.value;
    const p = promoGroupList[id];
    setPromoId(p.id);
    setGrpName(p.name);
    setPromo0(p.promo1);
    setPromo1(p.promo2);
    setPromo2(p.promo3);
    setPromo3(p.promo4);
    setPromo4(p.promo5);
    toggleModal();
    }

    const toggleModal =()=>{
        setShowHide(!showHide);
    }

    const addPromoHandler =()=>{
        setGrpName('');
        setPromo0('');
        setPromo1('');
        setPromo2('');
        setPromo3('');
        setPromo4('');
        setShowHide(true);
        setPromoId(0);
    }
    const newCardNameHandler =(e)=>{
        setNewcardName(e.target.value);
    }
    const getPromogroups =()=>{
      console.log("calling get promos here!!!");
        var url =Url+'/getpromogrps'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('PromosResponse received: '+res.data.msg);
            console.log("promolist: "+JSON.stringify(res.data.promos));
            if(res.data.msg==='ok'){
             
                setPromoGroupList(res.data.promos);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }
    const getCards = ()=>{
        console.log("calling get cards here!!!");
        var url =Url+'/getcardgroups'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                setCardGroupList(res.data.cards);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }
    const promoHandler =(e)=>{
        const id = e.target.id;
        const val = e.target.value;
        switch(id){
          case '0':
          setPromo0(val);
          break;
          case '1':
          setPromo1(val);
          break;
          case '2':
          setPromo2(val);
          break;
          case '3':
          setPromo3(val);
          break;
          case '4':
          setPromo4(val);
          break;
          default:
        }
    }

    const grpNameHandler =(e)=>{
      const val = e.target.value
      setGrpName(val);
    }

    const saveHandler =()=>{
      if(grpname.length===0){
        ShowMsg('Please enter Promotion Group Name!!',false);
        return;
      }
      if(promo0.length===0){
        ShowMsg('You need to fill in at least one promotion message!!',false);
        return;
      }
      
      var url =Url+'/addpromogrp'
      Axios.post(url,{
          id: promoid,
        name:grpname,
        promo1: promo0,
        promo2: promo1,
        promo3: promo2,
        promo4: promo3,
        promo5: promo4,
      }).then((res)=>{
        console.log('PromosResponse received: '+res.data.msg);
        toggleModal();
        if(res.data.msg==='ok'){
            //console.log('Promos ID Response received: '+res.data.id);
              console.log("promo id: "+promoid);
                if(promoid>0)
                ShowMsg('Promotion Group Updated Successfuly!!',true);
                else
                ShowMsg('Promotion Group Added Successfuly!!',true);
            setPromoGroupList(res.data.promos);
          
        }else{
          ShowMsg(res.data.pop,false);
          console.log("Received Cards list: "+JSON.stringify(res.data.promos));
          setPromoGroupList(res.data.promos);
        }
       
      });

    }

    const binRangeHandler =(e)=>{
        setBinRange(e.target.value);
    }

    const addRecord =()=>{
        if(newcardname.length===0){
            ShowMsg('Please enter Card Group Name!!',false);
            return;
          }
          if(binRange.length===0){
            ShowMsg('Please enter Bin Ranges that are comma seperated!!',false);
            return;
          }
       
          
       
          var url =Url+'/addcardgroup'
          Axios.post(url,{
              id: cardid,
            name:newcardname,
            binrange: binRange 
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                console.log('Cards ID Response received: '+res.data.id);
                if(res.data.id>0){
                    if(cardid>0)
                    ShowMsg('Card Group Updated Successfuly!!',true);
                    else
                    ShowMsg('Card Group Added Successfuly!!',true);
                }
                setCardGroupList(res.data.cards);
              
            }else{
              ShowMsg(res.data.pop,false);
              console.log("Received Cards list: "+JSON.stringify(res.data.cards));
              setCardGroupList(res.data.cards);
            }
          });
    }
return( 
<Container className='pb-4' fluid>
<Row>
<Col sm={6}>
<Card>
       <Card.Header><span className="App-header shadow-sm">Promo Configurations</span></Card.Header>
       <Card.Body>
       <InputGroup className="mb-3">
        <FormControl  
        placeholder="Promo Group Name..."
        aria-label="Promo Group Name..."
        aria-describedby="btnfindPromogrp"
        />
        <Button  variant="outline-info" id="btnfindPromogrp" onClick={()=>ShowMsg('Search Option Not Yet Implemented!!',false)}>
        Find Promo Group
        </Button>
        </InputGroup>
        <React.Fragment >
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Configured Promo Group List</h6></ListGroup.Item>
         {promoGroupList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>PromoGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={addPromoHandler}>NEW PROMO GROUP</Button>{' '}
       </Card.Footer>
</Card>
</Col>
<Col sm={6}>
</Col>
</Row>

<Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       dialogClassName={"modal-70w"}
       >
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >New Promotion Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group>
       <Form.Label>Promotion Group Name</Form.Label>
       <Form.Control sm={1} type="text" placeholder="Promo Group Name" value={grpname} onChange={grpNameHandler}/>
       </Form.Group>
       <CardGroup>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo1</Form.Label>
       <Form.Control id="0" type="text" placeholder="" value={promo0} onChange={promoHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Promo2</Form.Label>
       <Form.Control id="1" type="text" placeholder="" value={promo1} onChange={promoHandler}/>
       </Form.Group>

       </Card.Body>
       </Card>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo3</Form.Label>
       <Form.Control id="2" type="text" placeholder="" value={promo2} onChange={promoHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Promo4</Form.Label>
       <Form.Control id="3" type="text" placeholder="" value={promo3} onChange={promoHandler}/>
       </Form.Group>
       </Card.Body>
       </Card>
       <Card border='info'>
       <Card.Header variant='info'><h6>Promo Settings</h6></Card.Header>
       <Card.Body>
       <Form.Group>
       <Form.Label>Promo5</Form.Label>
       <Form.Control id="4" type="text" placeholder="" value={promo4} onChange={promoHandler}/>
       </Form.Group>

       </Card.Body>
       </Card>
       </CardGroup>
       </Form>
      
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="info" size="sm" className='mt-2' onClick={saveHandler} >SAVE SETTINGS</Button>{' '}

      </Modal.Footer>
      </Modal>
</Container>
);
}

export default PromoSettings;