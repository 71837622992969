import React, {useState,useEffect,useRef} from 'react';
import {Tabs,Tab,Modal,Form,Button,InputGroup,FormControl,Card,Row,Container,Col,ListGroup} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import {ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function ServicesSettings(...props){
    const [serviceGroupList,setServiceGroupList]= useState([]);
    const servicesRef = useRef(serviceGroupList);
    const [showHide,setShowHide] = useState(false);
    const [serviceChecked,setServiceChecked] =  useState(false);
    const [newcardname,setNewcardName] = useState('');
    const [binRange,setBinRange] = useState('');
    const [cardid,setCardId] = useState(0);
    const [service,setService]=useState({id:0,name:'',sale:false,cashback:false,balance:false,cardactivation:false,setpin:false,billpayment:false,mobilepayment:false,cash:false,intenal:false});
    const [checked,setChecked]=useState(false);
    useEffect(()=>{
      /*window.onkeydown = (e)=>{
        if(e.ctrlKey === true){
          e.preventDefault();
        }
       }*/

       window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
        if(serviceGroupList.length === 0 && serviceChecked === false){
            console.log('services scheme length: '+ serviceGroupList.length); 
        getServices();
        }else{
            console.log('Else services scheme length: '+ serviceGroupList.length);
        }
      },[serviceGroupList]);
    const servicesGroupInfoHandler = (e)=>{
        //todo: to add code to pop up cardgroup info
        e.preventDefault();
    var id = e.target.value;
    const s = serviceGroupList[id];
     setService(s);
    toggleModal();
    }

    const toggleModal =()=>{
        setShowHide(!showHide);
    }

    const addServicesHandler =()=>{
        setNewcardName('');
        setBinRange('');
        setShowHide(true);
        setCardId(0);
    }
    const serviceNameHandler =(e)=>{
        setService({...service,name:e.target.value});
    }
    const getServices = ()=>{
        console.log("calling get services here!!!");
        var url =Url+'/getservicegrps'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('ServicesResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                setServiceGroupList(res.data.services);
                setServiceChecked(true);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }

    const binRangeHandler =(e)=>{
        setBinRange(e.target.value);
    }

    const servicesHandler =(e)=>{
      var id = e.target.id;
      var s=false;
      if(e.target.checked)
      s=true;
      switch(id){
        case 'sale':
          setService({...service,sale:s});
          break;
          case 'cbk':
          setService({...service,cashback:s});
          break;
        case 'bal':
          setService({...service,balance:s});
          break;
        case 'dep':
          setService({...service,deposit:s});
          break;
        case 'spin':
          setService({...service,setpin:s});
          break;
        case 'act':
          setService({...service,cardactivation:s});
          break;
        case 'bill':
          setService({...service,billpayment:s});
          break;
        case 'cash':
          setService({...service,cash:s});
          break;
          case 'zinara':
            setService({...service,zinara:s});
            break;
        case 'mobile':
        setService({...service,mobilepayment:s});
        break;
          default:
      }

    }
    const clearHandler =()=>{
    setService({id:0,name:'',sale:false,cashback:false,balance:false,cardactivation:false,setpin:false,billpayment:false,mobilepayment:false,cash:false,intenal:false,zinara:false});
    }

    const addRecord =()=>{
      if(service.name.length===0){
        ShowMsg('Please enter Services Group Name!!',false);
        return;
      }
      Object.keys(service).forEach((key)=>{
        if(service[key]==='' || service[key]===null){
          ShowMsg('Please ensure that all fields are filled up!!',false);
          return; 
        }
    })
          
          var url =Url+'/addservicegrp'
          
          Axios.post(url,service).then((res)=>{
            console.log('ServicesResponse received: '+res.data.msg);
            console.log("Received Cards list: "+JSON.stringify(res.data.services));
            if(res.data.msg==='ok'){
                    if(service.id>0)
                    ShowMsg('Card Group Updated Successfuly!!',true);
                    else
                    ShowMsg('Card Group Added Successfuly!!',true);
            }else{
              ShowMsg(res.data.pop,false);             
            }
            setServiceGroupList(res.data.services);
          });
    }
return( 
<Container className='pb-4' fluid>
<Row>
<Col sm={6}>
<Card>
       <Card.Header><span className="App-header shadow-sm">Services Configurations</span></Card.Header>
       <Card.Body>
       <InputGroup className="mb-3">
        <FormControl  
        placeholder="Services Group Name..."
        aria-label="Services Group Name..."
        aria-describedby="btnfindServicesgrp"
        />
        <Button  variant="outline-info" id="btnfindServicesgrp" onClick={()=>ShowMsg('Search Option Not Yet Implemented!!',false)}>
        Find Services Group
        </Button>
        </InputGroup>
        <React.Fragment >
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Configured Services Group List</h6></ListGroup.Item>
         {serviceGroupList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>servicesGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={addServicesHandler}>New Services Group</Button>{' '}
       </Card.Footer>
</Card>
</Col>
<Col sm={6}>
</Col>
</Row>
<Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       dialogClassName={"modal-70w"}>
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >New Service Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Card>
       <Card.Body>
       <Form>
       <Form.Group>
       <Form.Label>Service Group Name</Form.Label>
       <Form.Control sm={1} type="text" placeholder="Promo Group Name" value={service.name} onChange={serviceNameHandler}/>
       </Form.Group>
       <Row>
       <Col  sm={3}>
       <Form.Group>
       <Form.Check id='sale' type="checkbox" onClick={servicesHandler} checked={service.sale} onChange={servicesHandler} label="Purchase"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='cbk' type="checkbox"  onClick={servicesHandler} checked={service.cashback} onChange={servicesHandler}  label="Cashback"/>
       
       </Form.Group>
       <Form.Group>
       <Form.Check id='bal'  type="checkbox"  onClick={servicesHandler} checked={service.balance} onChange={servicesHandler}  label="Balance"/>
       </Form.Group>
       </Col>
       <Col  sm={3}>
       <Form.Group>
       <Form.Check id='dep' type="checkbox"  onClick={servicesHandler} checked={service.deposit} onChange={servicesHandler}  label="Deposit"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='spin' type="checkbox"  onClick={servicesHandler} checked={service.setpin} onChange={servicesHandler} label="Setpin"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='act' type="checkbox"  onClick={servicesHandler} checked={service.activation} onChange={servicesHandler} label="Card Activation"/>
       </Form.Group>
       </Col>
       <Col  sm={3}>
       <Form.Group>
       <Form.Check id='bill' type="checkbox"  onClick={servicesHandler} checked={service.bills} onChange={servicesHandler} label="Bill Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='cash' type="checkbox"  onClick={servicesHandler} checked={service.cash} onChange={servicesHandler} label="Cash Withdrawal"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='mobile' type="checkbox"  onClick={servicesHandler} checked={service.mobilepayment} onChange={servicesHandler} label="Mobile Payment"/>
       </Form.Group>
       </Col>
       <Col  sm={3}>
       <Form.Group>
       <Form.Check id='zinara' type="checkbox"  onClick={servicesHandler} checked={service.zinara} onChange={servicesHandler} label="Zinara"/>
       </Form.Group>
       </Col>
       </Row>
       </Form>

       </Card.Body>
       </Card>
      
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={clearHandler}>CLEAR</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addRecord} >SAVE RECORD</Button>

      </Modal.Footer>
      </Modal>
</Container>
);
}

export default ServicesSettings;