import React, {Component} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';


class Contact extends  Component{
    
    render() {
        return (
            <div>
                This is my Contact Page
            </div>
        );
    }

}
export default Contact;
