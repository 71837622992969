import React, {useState,useEffect} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Card,Row,Container,Col} from 'react-bootstrap'
import CommsSettings from './commssettings';
import Cards from './cards';
import PromoSettings from './promosettings';
import ServicesSettings from './servicessettings';
import CardSettings from './cardssettings';
import CardsCfg from './cardscfg';
function GroupSettings(...props){
 return(
    <Container className="mt-1 " fluid>
        <Row>
          <Col  sm={12}  className="text-center"><span className="App-header shadow-sm">MERCHANT GROUP SETTINGS</span></Col>
          </Row>
          <Row>
          <Col  sm={12}>
          <Card className=" pb-1">
          <Card.Body>
          <Tabs className="pb-3" forceRenderTabPanel defaultIndex={0}>
                <TabList>
                <Tab>Services</Tab>
                <Tab>Cards</Tab>
                <Tab>Communications</Tab>
                <Tab>Promotions</Tab>
                </TabList>
              <TabPanel>
              <ServicesSettings/>
              </TabPanel>
              <TabPanel >
                  <CardSettings/>
              </TabPanel>
              <TabPanel >
                 <CommsSettings/>
              </TabPanel>
              <TabPanel >
                <PromoSettings/> 
              </TabPanel>
          </Tabs>

          </Card.Body>
          </Card>
          </Col>
          </Row>
    </Container>
 )
}
export default GroupSettings;