import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "flatpickr/dist/themes/material_green.css";
import 'react-tabs/style/react-tabs.css';
import LoginForm from './Login';
import RegForm from './signup';
import Dashboard from './components/dash';
import TestApp from './components/test';
import './config.js';
import {BrowserRouter as Router,Routes, Route} from 'react-router-dom';
//import Route from 'react-router-dom/Route';
import ProtectedRoute from './components/protectedroute';
import {useState} from 'react';
function App() {
  const [isAuth, setIsAuth]= useState(false);
  //  console.log("isAuth Status: "+isAuth);
  return (

    <Router>
      <Routes>
      <Route path="/auth" element={<LoginForm onLogin={(x)=>{setIsAuth(x)}}/>}/>
    <Route path='/signup' element={<RegForm />}/>
    <Route element={<ProtectedRoute auth={isAuth}/>}>
    <Route path='/' element={<Dashboard />}/>
    </Route>
      </Routes>
    
    </Router>
  );
}

export default App;
