import React, {useState,useEffect} from 'react'
import {Card,Row,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Users from './users';
import Profiles from './profiles';
import {EventAllowed} from './profilecfg';
import ChangePassword from './passwords';
import {Url} from './url';
import Axios from 'axios';
import {ROLEOPS} from './constants/constants';
import MerchantMushumo from './mushumomerchant';
import TerminalMushumo from './mushumoterminal';
import TestMushumo from './testmushumo';
import ZinaraZwl from './zinara_zwl';

function Reports ({...props}){



const [gottolls,setGotTolls] = useStateWithCallbackLazy(false);
const [zwltolls,setZwlTolls]= useStateWithCallbackLazy({});
const [usdtolls,setUsdTolls]= useStateWithCallbackLazy({});

useEffect(()=>{ 
      
  window.onkeydown = (e)=>{
   if(e.ctrlKey === true || e.metaKey === true){
     if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
     e.preventDefault();
   }
  }
 });
 const getTollFeesHandler = async ()=>{
  var url =Url+'/gettolls';
  const data={};
  Axios.post(url,data).then(async (res)=>{
    console.log('get fees Response received: '+JSON.stringify(res.data));
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        setZwlTolls(res.data.data[0],()=>{
          console.log("Done setting zwl toll fees");
        });
        if(res.data.data.length>0){
           
          if(res.data.data.length>1)
       setUsdTolls(res.data.data[1],()=>{
          console.log("Done setting usd toll fees");
        });
      }
        setGotTolls(true,()=>{
          console.log("gottolls is true");
      });
      }else{
        clearRoles();
      }

    }
  ); 
 }
 
 
 
 const clearRoles =()=>{
  
 }
 

  return(

    <Container className="mt-2 pb-5" fluid>
    <Row>
    <Col  sm={12}  className="text-center"><span className="App-header shadow-sm">Merchant And Terminal Reports</span></Col>
    </Row>
    <Row>
    <Col  sm={12}>
    <Card className=" pb-1">
    <Card.Body>
    <Tabs className="pb-3" forceRenderTabPanel defaultIndex={0}>
          <TabList>
          <Tab>Merchant Report</Tab>
          <Tab>Terminal Report</Tab> 
          {/* <Tab>Test Report</Tab> */}
          </TabList>
        <TabPanel>
        <MerchantMushumo />
        </TabPanel>
        <TabPanel>
        <TerminalMushumo/>
        </TabPanel> 
        {/* <TabPanel>
        <TestMushumo/>
        </TabPanel> */}
    </Tabs>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

  )
}

export default Reports;
