//const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,roleid:0,roletype:1};
export const ROLEOPS ={
USER:1,
MERCHANT:2,
TERMINAL:3,
AUDIT:4,
GENERAL:5,
PROFILE:6,
SOFT:7,
ZINARA: 8,
GROUP_SETTINGS: 9,
DELETE: 10,
}

export const USER_PROF ={
    CLERCK: 1,
    SUPERVISOR: 2,
    MANAGER: 3,
    ADMINISTRATOR: 4,
    PRIME: 5,
    OPTIMUS_PRIME: 6,
}

export const LoggedInUser = ()=>{
    const role = sessionStorage.getItem('role');
   // console.log("Logged in user Prof ID: "+role);
   switch(role){
       case 6:
           return USER_PROF.OPTIMUS_PRIME;
       case 5:
           return USER_PROF.PRIME;
       case 4:
        console.log("returning Logged in Prof ID: "+USER_PROF.ADMINISTRATOR);
           return USER_PROF.ADMINISTRATOR;
       case 3:
           return USER_PROF.MANAGER;
       case 2:
           return USER_PROF.SUPERVISOR;         
       default:
           return USER_PROF.CLERCK;

   }
}