import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Modal,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
import { Redirect } from "react-router";
import { useStateWithCallbackLazy } from 'use-state-with-callback';

function ZinaraTariffNames(...props){
    const [toggleform,setToggleForm] = useState(false);
    const [gname,setGname] = useState('');
    const [tlist,setTlist] = useState([]);
    const [datachanged,setDataChanged] = useState(true);
  const [id,setId]= useState(0);
    const refresh = async (silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/gettgnames';
     const data ={};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            clearLiteHandler();
            setId('0');
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("refreshing Tariff group Name data: "+JSON.stringify(res.data.data));
            setDataChanged(false);
            const f = res.data.data;
                setTlist(f,()=>{
                    //todo: populate on UI
                });
                
            
         }
     });
    }

    //todo: getting tolls here on scree render
    /*if(firstrun){
        refresh(true);
        setFirstRun(false);
    }*/

    useEffect(()=>{ 
        window.onkeydown = (e)=>{
            if(e.ctrlKey === true || e.metaKey === true){
              if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
              e.preventDefault();
            }
           }
        async function refreshdata(){
            console.log("getting toll tariff names here!!");
            await refresh(true);
            console.log("tariff group names: "+JSON.stringify(tlist));
          };

          if(datachanged){
            refreshdata();
          }
          
    },[datachanged]);

   
   

    const updateGroupTariffName =()=>{
     if(gname.length===0 ){
         ShowMsg("Please Enter Group Tariff Name, As Expected!!",false);
         return;
     }

    
     const idd = sessionStorage.getItem('ndiani');
     var url =Url+'/addtgname';
     const data ={id:id, name:gname,};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ok'){
            clearLiteHandler();
            setDataChanged(true);
             ShowMsg(res.data.pop,true);
         }else{
             ShowMsg(res.data.pop,false);
         }
     })
    }

 const clearLiteHandler  = ()=>{
        setGname('');
        setId(0);
 }  

    

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnupdate':
          updateGroupTariffName();
          break;
          case 'btnrefresh':
         refresh(false);
          break;
          case 'btnreset':
            clearLiteHandler();
          break;
          default:
        }
    }

    

    const gtHandler = (e)=>{
        var val = e.target.value;
        setGname(val);  
    }

    const gtEditHandler =(e)=>{
        const idx = e.target.value;
        if(tlist.length > idx){
            const t = tlist[idx];
            setId(t.id);
            setGname(t.name);
        }
    }

    
   
    return(
        <Container className="mt-2" fluid>
    <Row className="pt-2 ">
    <Col  sm={8}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <Form>
    <Form.Group >
    <Form.Label>Zinara Tariff Group Name</Form.Label>
    <Form.Control id='gt' autoComplete={'nope3'} type="text" placeholder="Tariff Name..." value={gname}  onChange={gtHandler}/>
    </Form.Group>
    </Form>
    </Card.Body>
    <Card.Footer>
      <Button id='btnupdate' variant="info" onClick={btnHandler} size="sm" >SAVE TARIFF NAME</Button>{' '}
      <Button id='btnreset' variant="outline-info" size="sm" onClick={clearLiteHandler} >RESET</Button>{' '}
      <Button id='btnrefresh' variant="outline-info" size="sm" onClick={btnHandler} >REFRESH</Button>{' '}
    </Card.Footer>
    </Card>
    </Col>
    <Col  sm={4}className=" pb-1 pt-2 sform">
    <React.Fragment>
         <ListGroup>
         <ListGroup.Item  variant="dark"><h6>Current Tariff group Names</h6></ListGroup.Item>
         {tlist.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>gtEditHandler(e)} variant='info'>
           {item.name} 
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
    </Col>
    </Row>
    </Container>
    )


}
export default ZinaraTariffNames;