import React from 'react';
import {Dropdown,Button} from 'react-bootstrap'
class MenuButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value:null,
    }
  }
  render(){
    return(
      <div className='px-3 mt-4 mb-1 text-muted d-grid '>
        <Button variant="outline-info" size="sm"   onClick={()=>{this.props.runCode()}}>  {this.props.value}</Button> 
    </div>
  );
  }
}
class RadioButton extends React.Component{
  constructor(props){
    super(props);

  }
  render(){
    return(
      <div className="form-check mx-sm-3 mb-2 form-check">
      <input className="form-check-input" type="radio" name="rad" value={this.props.value} checked={this.props.checked} />
        <label className="form-check-label" for="nsig">
          {this.props.label}
        </label>
      </div>
  );
  }
}

class SignOutButton extends React.Component{


  render(){
    return(
    <Dropdown size={'sm'}>
      <Dropdown.Toggle variant='info'>
        {'Hi '+ this.props.code}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" onClick={this.props.runCode}>Sign Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> 
   
  );
  }
}


class Select extends React.Component{
  constructor(props){
    super(props);
  this.state ={
    branches:[],
  }
  }


 render(){
   return(
   <select name={this.props.name} onChange={this.props.onChange} className="form-control" >
   <option selected value="">Select Shop Branch</option>
   {
     this.props.dlist.map((opt)=>(
       <option value={opt.value}>{opt.text}</option>
     ))
   }
   </select>

)
 }
}
export {RadioButton,MenuButton,Select,SignOutButton};
