import {useState,useEffect} from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import React from 'react';
import {Modal,Form,Button,InputGroup,FormControl,Card,Row,ListGroup,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {ShowMsg,Msg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function EmvLimits(...props){
    const [isDone,setDone]=useState(false);
    const [emvGroupList,setEmvGroupList]=useState([]);
    const [emvSelGroupList,setEmvSelGroupList]=useState([]);
    const [currencyCfg,setCurrencuCfg]=useState([{curs:'ZWL',curc:'932'},{curs:'USD',curc:'840'},{curs:'ZIG',curc:'959'}]);
    const [id,setId]=useState('0');
    const [rid,setRid]=useState('');
    const [name,setName]=useState('');
    const [floorlim,setFloorLim]=useState(0);
    const [translim,setTransLim]=useState(0);
    const [cvmlim,setCvmLim]=useState(0);
    const [ctlsfloorlim,setCtlsFloorLim]=useState(0);
    const [ctlstranslim,setCtlsTransLim]=useState(0);
    const [ctlscvmlim,setCtlsCvmLim]=useState(0);
    const [currencycode,setCurrencyCode]=useState('932');
    const [currencynum,setCurrencyNum]=useState('932');
    const [showHide,setShowHide] = useState(false);
    const ndani =sessionStorage.getItem('ndiani');
    const [uid,setUid]= useState('');
    useEffect(()=>{

        window.onkeydown = (e)=>{
            if(e.ctrlKey === true || e.metaKey === true){
              if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
              e.preventDefault();
            }
           }
        
        if(emvGroupList.length === 0 && !isDone){
            console.log('emvgrouplist length: '+ emvGroupList.length); 
        getEmvGrpList();
        setUid(ndani);
        
        }else{
            console.log('Else emvgrouplis length: '+ emvGroupList.length);
            getStartupAid();
        }
      },[emvGroupList]);

      const getEmvGrpList = async()=>{
        console.log("calling get emvgrplist here!!!");
        var url =Url+'/getemvlimitslist'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                setEmvGroupList(res.data.data);
            }else{
              //ShowMsg(res.data.pop,false);          
            }
            setDone(true);
          });
      }

      const getStartupAid = ()=>{
        var id =currencynum;
        var list=[];
        var i=0;
        setEmvSelGroupList([]);
        
        console.log("startup Emvgroup List: "+JSON.stringify(emvGroupList));
        for(i=0; i<emvGroupList.length; i++){
            const emv = emvGroupList[i];
            console.log("startup selected Currency: "+id+" cfg currency: "+emv.currencycode);
            if(emv.currencycode===parseInt(id)){
                list.push(emv);
            }
        }
        console.log("Currency selected: aid size: "+list.length);
        setEmvSelGroupList(list);
      }

      const getSelectedAids =(e)=>{
        var id = e.target.value;
        setCurrencyNum(id);
        var list=[];
        var i=0;
        setEmvSelGroupList([]);
        
        console.log("Emvgroup List: "+JSON.stringify(emvGroupList));
        for(i=0; i<emvGroupList.length; i++){
            const emv = emvGroupList[i];
            console.log("selected Currency: "+id+" cfg currency: "+emv.currencycode);
            if(emv.currencycode===parseInt(id)){
                list.push(emv);
            }
        }
        console.log("Currency selected: aid size: "+list.length);
        setEmvSelGroupList(list);

      }

      const toggleModal =()=>{
        setShowHide(!showHide);
    }

    const addRecord =(e)=>{
        const data ={
            id:id,
            rid:rid,
            name:name,
            floorlim:floorlim,
            translim:translim,
            cvmlim:cvmlim,
            ctlsfloorlim:ctlsfloorlim,
            ctlstranslim:ctlstranslim,
            ctlscvmlim:ctlscvmlim,
            currencycode:currencycode,
            uid:uid,
        };
        console.log("calling post record here!!!");
        var url =Url+'/updateoneemvlimit'
          Axios.post(url,data).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
                ShowMsg(res.data.pop,true); 
            }else{
                ShowMsg(res.data.pop,false);          
            }
            toggleModal();
            refresh();

          });
    }

    const refresh = (e)=>{
        getEmvGrpList();
    }

    const emvGroupInfoHandler = (e)=>{
        e.preventDefault();
        var id = e.target.value;
        const emv = emvSelGroupList[id];
        setId(emv.id);
        setRid(emv.rid);
        setName(emv.name);
        setFloorLim(emv.floorlim);
        setTransLim(emv.translim);
        setCvmLim(emv.cvmlim);
        setCtlsFloorLim(emv.ctlsfloorlim);
        setCtlsTransLim(emv.ctlstranslim);
        setCtlsCvmLim(emv.ctlscvmlim);
        setCurrencyCode(emv.currencycode);
        toggleModal();


    }
    const clearcfgs =()=>{
        setId('0');
        setRid('');
        setName('');
        setFloorLim('');
        setTransLim('');
        setCvmLim('');
        setCtlsFloorLim('');
        setCtlsTransLim('');
        setCtlsCvmLim('');
        setCurrencyCode('932');

    }

    const addcfg =()=>{
        clearcfgs();
        toggleModal();
    }

      const limitHandler =(e)=>{
        const id = e.target.id;
        const val = e.target.value;
        switch(id){
            case 'rid':
            setRid(val);
            break;
            case 'fl':
            setFloorLim(val);
            break;
            case 'tl':
            setTransLim(val);
            break;
            case 'cl':
            setCvmLim(val);
            break;
            case 'cfl':
            setCtlsFloorLim(val);
            break;
            case 'ctl':
            setCtlsTransLim(val);
            break;
            case 'ccl':
            setCtlsCvmLim(val);
            break;
            case 'cnum':
            setCurrencyCode(val);
            break;
            case 'ename':
            setName(val);
            break;
            default:
        }
      }

      return(
            <Container className='pb-4' fluid>
            <Row>
            
        <Col xs={8}>
            <Card>
                <Card.Header><span className="App-header shadow-sm">Emv Limit Configurations</span></Card.Header>
                <Card.Body>
                <InputGroup className="mb-3">
                <Form.Group>
                <Form.Label>Select Config Currency</Form.Label>
                <Form.Select id="cnum" as="select" size="sm" onChange={getSelectedAids} value={currencynum} custom >   
                    <option value='924'>ZWG</option>
                    <option value='840'>USD</option>
                    <option value='959'>ZiG</option>
                </Form.Select>
                </Form.Group>
                </InputGroup>
                <React.Fragment >
                <ListGroup>
                <ListGroup.Item  variant="dark"><h6>Configured Emv Limit Group List</h6></ListGroup.Item>
                {emvSelGroupList.map((item, i)=>(
                <ListGroup.Item action value={i} onClick={(e)=>emvGroupInfoHandler(e)} variant='info'>
                {item.name}
                </ListGroup.Item>
                ))}
                </ListGroup>
                </React.Fragment>      
                       
                        
                </Card.Body>
                <Card.Footer className="text-muted">
                <Button variant="info" size="sm" onClick={refresh} >Refresh</Button>{' '}
                <Button variant="outline-info" size="sm" onClick={clearcfgs} >Clear</Button>{' '}
                <Button variant="outline-info" size="sm" onClick={addcfg} >Add Config</Button>{' '}
                </Card.Footer>
                    </Card>
                    </Col>
                    <Col></Col> 
                    <Col>
                     </Col> 
            </Row>

            <Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       size="md">
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title  >New Emv Limits Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
      <Form.Group>
      <Form.Label>Card RID</Form.Label>
      <Form.Control id="ename" type="text" placeholder="AID Name...." value={name} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Card RID</Form.Label>
      <Form.Control id="rid" type="text" placeholder="RID...." value={rid} onChange={limitHandler}/>
      </Form.Group>
     
      <Form.Group>
      <Form.Label>Floor Limit</Form.Label>
      <Form.Control id="fl" type="text" placeholder="Floor Limit" value={floorlim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Transaction Limit</Form.Label>
      <Form.Control id="tl" type="text" placeholder="Transaction Limit" value={translim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>CVM Limit</Form.Label>
      <Form.Control id="cl" type="text" placeholder="CVM Limit" value={cvmlim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Contactless Floor Limit</Form.Label>
      <Form.Control id="cfl" type="text" placeholder="Ctls Floor Limit" value={ctlsfloorlim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Contactless Transaction Limit</Form.Label>
      <Form.Control id="ctl" type="text" placeholder="Ctls Transaction Limit" value={ctlstranslim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
      <Form.Label>Contactless CVM Limit</Form.Label>
      <Form.Control id="ccl" type="text" placeholder="Ctls CVM Limit" value={ctlscvmlim} onChange={limitHandler}/>
      </Form.Group>
      <Form.Group>
         <Form.Label>Currency Code</Form.Label>
         <Form.Select id="cnum"  size="sm" onChange={limitHandler} value={currencycode} custom >   
            <option value='924'>ZWG</option>
            <option value='840'>USD</option>
            <option value='959'>ZiG</option>
         </Form.Select>
         </Form.Group>
      </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addRecord} >SAVE RECORD</Button>

      </Modal.Footer>
      </Modal>

            </Container>

      );
}
export default EmvLimits;