import React, {useState,useEffect} from 'react'
import {Card,Row,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import Users from './users';
import Profiles from './profiles';
import {EventAllowed} from './profilecfg';
import ChangePassword from './passwords';
import {Url} from './url';
import Axios from 'axios';
import {ROLEOPS} from './constants/constants';
import ZinaraUsd from './zinara_usd';
import ZinaraZwl from './zinara_zwl';
import ZinaraTariffNames from './zinara_tariffnames';
function ZINARASettings ({...props}){



const [gottolls,setGotTolls] = useStateWithCallbackLazy(false);
const [zwltolls,setZwlTolls]= useStateWithCallbackLazy({});
const [usdtolls,setUsdTolls]= useStateWithCallbackLazy({});


 const getTollFeesHandler = async ()=>{
  var url =Url+'/gettolls';
  const data={};
  Axios.post(url,data).then(async (res)=>{
    console.log('get fees Response received: '+JSON.stringify(res.data));
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        setZwlTolls(res.data.data[0],()=>{
          console.log("Done setting zwl toll fees");
        });
        if(res.data.data.length>0){
           
          if(res.data.data.length>1)
       setUsdTolls(res.data.data[1],()=>{
          console.log("Done setting usd toll fees");
        });
      }
        setGotTolls(true,()=>{
          console.log("gottolls is true");
      });
      }else{
        clearRoles();
      }

    }
  ); 
 }
 
 
 
 const clearRoles =()=>{
  
 }
 

  return(

    <Container className="mt-2 pb-5" fluid>
    <Row>
    <Col  sm={12}  className="text-center"><span className="App-header shadow-sm">ZINARA Settings</span></Col>
    </Row>
    <Row>
    <Col  sm={12}>
    <Card className=" pb-1">
    <Card.Body>
    <Tabs className="pb-3" forceRenderTabPanel defaultIndex={0}>
          <TabList>
          <Tab>ZWG Toll Fees</Tab>
          <Tab>USD Toll Fees</Tab>
          <Tab>Add Group Tariff</Tab>
          </TabList>
        <TabPanel>
        <ZinaraZwl />
        </TabPanel>
        <TabPanel>
        <ZinaraUsd  />
        </TabPanel>
        <TabPanel>
        <ZinaraTariffNames />
        </TabPanel>
    </Tabs>
    </Card.Body>
    </Card>
    </Col>
    </Row>
    </Container>

  )
}

export default ZINARASettings;
