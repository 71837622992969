import {useState,useEffect} from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {ShowMsg,Msg} from './pop';
import {ROLEOPS,USER_PROF} from './constants/constants';
import {EventAllowed} from './profilecfg';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
function Profiles ({mid:mid,...props}){
  const e ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,zinara:false,delet:false,groupsettings:false,roleid:0,roletype:1};
  const c ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,zinara:false,delet:false,groupsettings:false,roleid:0,roletype:2};
  const w ={id:0,user:false,role:false,merchant:false,soft:false,terminal:false,audit:false,general:false,zinara:false,delet:false,groupsettings:false,roleid:0,roletype:3};
const [entries,setEntries]= useState(e);
const [changes,setChanges]= useState(c);
const [views,setViews]= useStateWithCallbackLazy(w);
const [roleid,setRoleId] = useStateWithCallbackLazy(0);
const [isSpin,setSpin] = useState(false);
const [gotroles, setGotRoles]= useState(false);
const rid =parseInt(sessionStorage.getItem('role'));



const clearRoles =()=>{
  setEntries({...e,roleid:roleid});
  setChanges({...c,roleid:roleid});
  setViews({...w,roleid:roleid});
  console.log("Views.user: "+views.user+ " #w.user default: "+w.user);
}

const getAutoRolesHandler = async ()=>{
  //await timeout(2000);
  var data={roleid:  sessionStorage.getItem('role')};
  setRoleId(sessionStorage.getItem('role'));
     var url =Url+'/getroles';
     console.log("geroles url: "+url);
  console.log('Doing Get roles POST here!!! roleID: '+roleid);
  Axios.post(url,data).then((res)=>{
    console.log('get roles Response received: '+JSON.stringify(res.data));
    setSpin(false);
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        setGotRoles(true);
        if(res.data.roles.length>0){
        setEntries(res.data.roles[0]);
        setChanges(res.data.roles[1]);
        setViews(res.data.roles[2]);
      }else{
        clearRoles();
      }

    }
  });
};

const getRolesHandler = async ()=>{
  //await timeout(2000);
  var data={roleid:  roleid};
     var url =Url+'/getroles';
     console.log("geroles url: "+url);
  console.log('Doing Get roles POST here!!! roleID: '+roleid);
  Axios.post(url,data).then((res)=>{
    console.log('get roles Response received: '+JSON.stringify(res.data));
    setSpin(false);
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        if(res.data.roles.length>0){
        setEntries(res.data.roles[0]);
        setChanges(res.data.roles[1]);
        setViews(res.data.roles[2]);
      }else{
        clearRoles();
      }

    }
  });
};

const fetchRolesHandler = async (id)=>{
  //await timeout(2000);
  var data={roleid:  id};
     var url =Url+'/getroles';
     console.log("geroles url: "+url);
  console.log('Doing Get roles POST here!!! roleID: '+id);
  Axios.post(url,data).then((res)=>{
    console.log('get roles Response received: '+JSON.stringify(res.data));
    setSpin(false);
    if(res.data.msg==='ok'){
        console.log('profile data received OK!!');
        if(res.data.roles.length>0){
        setEntries(res.data.roles[0]);
        setChanges(res.data.roles[1]);
        setViews(res.data.roles[2]);
      }else{
        clearRoles();
      }

    }
  });
};

const updateroledata = async(roleid)=>{
  console.log("Current roleid: "+roleid);
  setEntries({...entries,roleid:roleid});
  setChanges({...changes,roleid:roleid});
  setViews({...views,roleid:roleid},()=>{
      console.log("Updated roleid on views: "+views.roleid);
  });
}

const saveRolesHandler = async()=>{
  // if(!EventAllowed(entries,ROLEOPS.PROFILE)){
  //   ShowMsg(Msg.ROLE_EDIT,false)
  //   return
  // } 

  //await updateroledata(roleid);
 
  var data={roles: [entries,changes,views],roleid:roleid};
  console.log('Profile Data to send: '+JSON.stringify(data));
     var url =Url+'/updateroles';
  console.log('Doing update roles POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('update roles Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('User data received OK!!');
        setEntries(res.data.roles[0]);
        setChanges(res.data.roles[1]);
        setViews(res.data.roles[2]);
        ShowMsg(res.data.pop,true);
    }else{
      ShowMsg(res.data.pop,false);
    }
  });
}

const refreshRoles =()=>{
  // if(!EventAllowed(views,ROLEOPS.PROFILE)){
  //   ShowMsg(Msg.ROLE_FIND,false)
  //   return
  // } 
  setSpin(true);
  getRolesHandler();
}

const fetchSelectedProfile = (id)=>{
  setSpin(true);
  fetchRolesHandler(id);
}

const roleHandler = (e)=>{
  // if(!EventAllowed(views,ROLEOPS.PROFILE)){
  //   ShowMsg(Msg.ROLE_FIND,false)
  //   return
  // } 
  
  // if(!(EventAllowed(entries,ROLEOPS.PROFILE) || EventAllowed(changes,ROLEOPS.PROFILE ))){
  //   ShowMsg(Msg.ROLE_EDIT,false)
  //   return
  // } 
  
    clearRoles();
  const val = e.target.value;
  console.log("selected Rolde ID: "+val);
  setEntries({...entries,roleid:val});
  setChanges({...changes,roleid:val});
  setViews({...views,roleid:val});
  setRoleId(val,(id)=>{
    console.log("selected roleid: "+val+" setRolId: "+id)
    fetchSelectedProfile(id);
  });
 
  console.log('Here b4 updating profiles');
//  saveRolesHandler();

}

useEffect(()=>{  
  window.onkeydown = (e)=>{
    if(e.ctrlKey === true || e.metaKey === true){
      if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
      e.preventDefault();
    }
   }
  async function fetchdata(){
    console.log("getting roles here!!");
    await getAutoRolesHandler();
    //console.log("viewes.user: "+views.user);
  }
  if(!gotroles)   
  fetchdata();
},[getAutoRolesHandler, gotroles]);

const entriesHandler = (e)=>{
  const val = e.target.value;
  updateroledata(roleid);
  switch(e.target.id){
  case 'chkuser':
  if(e.target.checked){
    setEntries({...entries,user:true});
  }else{
  setEntries({...entries,user:false});
  }
  break;
  case 'chkprof':
  if(e.target.checked){
    setEntries({...entries,role:true});
  }else{
    setEntries({...entries,role:false});
  }
  break;
  case 'chkmerch':
  if(e.target.checked){
  setEntries({...entries,merchant:true});
  }else{
    setEntries({...entries,merchant:false});
  }
  break;
  case 'chksoft':
  if(e.target.checked){
    if((rid <= USER_PROF.PRIME) ){
      ShowMsg(Msg.SOFTWARE_GRP_EDIT,false)
      return;
    }
    setEntries({...entries,soft:true});
  }else{
    setEntries({...entries,soft:false});
  }
  break;
  case 'chkterm':
  if(e.target.checked){
    setEntries({...entries,terminal:true});
  }else{
    setEntries({...entries,terminal:false});
  }
  break;
  case 'chkzinara':
  if(e.target.checked){
    setEntries({...entries,zinara:true});
  }else{
    setEntries({...entries,zinara:false});
  }
  break;
  case 'chkdelete':
  if(e.target.checked){
    setEntries({...entries,delet:true});
  }else{
    setEntries({...entries,delet:false});
  }
  break;
  case 'chkgs':
  if(e.target.checked){
    setEntries({...entries,groupsettings:true});
  }else{
    setEntries({...entries,groupsettings:false});
  }
  break;
  default:

}

}

const changesHandler = (e)=>{
  const val = e.target.value;
  updateroledata(roleid);
  switch(e.target.id){
  case 'chkusers':
  if(e.target.checked){
    setChanges({...changes,user:true});
  }else{
    setChanges({...changes,user:false});
  }
  break;
  case 'chkprofs':
  if(e.target.checked){
    setChanges({...changes,role:true});
  }else{
    setChanges({...changes,role:false});
  }
  break;
  case 'chkmerchs':
  if(e.target.checked){
  setChanges({...changes,merchant:true});
  }else{
    setChanges({...changes,merchant:false});
  }
  break;
  case 'chksofts':
  if(e.target.checked){
    if((rid <= USER_PROF.PRIME) ){
      ShowMsg(Msg.SOFTWARE_GRP_EDIT,false)
      return;
    }
    setChanges({...changes,soft:true});
  }else{
    setChanges({...changes,soft:false});
  }
  break;
  case 'chkterms':
  if(e.target.checked){
    setChanges({...changes,terminal:true});
  }else{
    setChanges({...changes,terminal:false});
  }
  break;
  case 'chkzinaras':
    if(e.target.checked){
      setChanges({...changes,zinara:true});
    }else{
      setChanges({...changes,zinara:false});
    }
    break;
    case 'chkdeletes':
    if(e.target.checked){
      setChanges({...changes,delet:true});
    }else{
      setChanges({...changes,delet:false});
    }
    break;
    case 'chkgss':
    if(e.target.checked){
      setChanges({...changes,groupsettings:true});
    }else{
      setChanges({...changes,groupsettings:false});
    }
    break;
  default:

}

}

const viewsHandler = (e)=>{
  const val = e.target.value;
  updateroledata(roleid);
  switch(e.target.id){
  case 'chkvusers':
  if(e.target.checked){
    setViews({...views,user:true});
  }else{
    setViews({...views,user:false});
  }
  break;
  case 'chkvprofs':
  if(e.target.checked){
    setViews({...views,role:true});
  }else{
    setViews({...views,role:false});
  }
  break;
  case 'chkvmerchs':
  if(e.target.checked){
    setViews({...views,merchant:true});
  }else{
    setViews({...views,merchant:false});
  }
  break;
  case 'chkvsofts':
  if(e.target.checked){
    if((rid <= USER_PROF.PRIME) ){
      ShowMsg(Msg.SOFTWARE_GRP_EDIT,false)
      return;
    }
    setViews({...views,soft:true});
  }else{
    setViews({...views,soft:false});
  }
  break;
  case 'chkvterms':
  if(e.target.checked){
    setViews({...views,terminal:true});
  }else{
    setViews({...views,terminal:false});
  }
  break;
  case 'chkvaudit':
  if(e.target.checked){
    setViews({...views,audit:true});
  }else{
    setViews({...views,audit:false});
  }
  break;
  case 'chkvgrep':
  if(e.target.checked){
    setViews({...views,general:true});
  }else{
    setViews({...views,general:false});
  }
  break;
  case 'chkvzinara':
  if(e.target.checked){
    setViews({...views,zinara:true});
  }else{
    setViews({...views,zinara:false});
  }
  break;
  case 'chkvdelete':
  if(e.target.checked){
    setViews({...views,delet:true});
  }else{
    setViews({...views,delet:false});
  }
  break;
  case 'chkvgs':
  if(e.target.checked){
    setViews({...views,groupsettings:true});
  }else{
    setViews({...views,groupsettings:false});
  }
  break;

  default:

}

}

  return(
    
    <Container className="mt-2" fluid>
      <Form>
    <Row className="pt-2 border-bottom">
    <Col sm={4}>
    <Form.Group>
   <Form.Select id='cboroles' size="sm" onChange={roleHandler} value={roleid}  custom >
      <option value='0'>Select User Profile</option>
      <option value='1'>Clerck</option>
      {(rid>= 3) ? <option value='2'>Supervisor</option>:''}
      {(rid >= 4) ? <option value='3'>Manager</option>:''}
      {(rid >= 5) ? <option value='4'>Administrator</option>:''}
      {(rid >= 5)? <option value='5'>Prime</option>:''}
   </Form.Select>
        
   </Form.Group>
   </Col>
   <Col sm={4}>
   {showSpinDiag(isSpin,'Getting Profile Data')}
     </Col>
    </Row>
    <Row className="mt-1">
    <Col  sm={12}>
    <Card className=" pb-1 pt-2">
    <Card.Body>

    <Row>
    <Col sm={4}>
    <Card >
    <Card.Header className="text-center"><h5>Entries</h5></Card.Header>
    <Card.Body>
    <Form.Group>
    <Form.Check id='chkuser' type="checkbox" onClick={entriesHandler} checked={entries.user}   label="User"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkprof' type="checkbox" onClick={entriesHandler} checked={entries.role}    label="Profile"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkmerch' type="checkbox" onClick={entriesHandler} checked={entries.merchant}    label="Merchant"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chksoft' type="checkbox" onClick={entriesHandler} checked={entries.soft}   label="Software Group"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkterm' type="checkbox" onClick={entriesHandler} checked={entries.terminal}    label="Terminal"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkzinara' type="checkbox" onClick={entriesHandler} checked={entries.zinara}    label="Zinara"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkdelete' type="checkbox" onClick={entriesHandler} checked={entries.delet}    label="Delete Record"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkgs' type="checkbox" onClick={entriesHandler} checked={entries.groupsettings}    label="Group Settings"/>
    </Form.Group>
    </Card.Body>
    </Card>
    </Col>
    <Col sm={4}>
    <Card className="">
    <Card.Header className="text-center"><h5>Modifications</h5></Card.Header>
    <Card.Body>
    <Form.Group>
    <Form.Check id='chkusers' type="checkbox" onClick={changesHandler} checked={changes.user}    label="Users"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkprofs' type="checkbox" onClick={changesHandler} checked={changes.role}   label="Profiles"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkmerchs' type="checkbox" onClick={changesHandler} checked={changes.merchant}  label="Merchants"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chksofts' type="checkbox" onClick={changesHandler} checked={changes.soft}  label="Software Groups"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkterms' type="checkbox" onClick={changesHandler} checked={changes.terminal}  label="Terminals"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkzinaras' type="checkbox" onClick={changesHandler} checked={changes.zinara}    label="Zinara"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkdeletes' type="checkbox" onClick={changesHandler} checked={changes.delet}    label="Delete Record"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkgss' type="checkbox" onClick={changesHandler} checked={changes.groupsettings}    label="Group Settings"/>
    </Form.Group>
    </Card.Body>
    </Card>
    </Col>
    <Col sm={4}>
    <Card className="">
    <Card.Header className="text-center"><h5>Views</h5></Card.Header>
    <Card.Body>
    <Form.Group>
    <Form.Check id='chkvusers' type="checkbox" onClick={viewsHandler} checked={views.user}   label="Users"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvprofs' type="checkbox" onClick={viewsHandler} checked={views.role}    label="Profiles"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvmerchs' type="checkbox" onClick={viewsHandler} checked={views.merchant}    label="Merchants"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvsofts' type="checkbox" onClick={viewsHandler} checked={views.soft}    label="Software Groups"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvterms' type="checkbox" onClick={viewsHandler} checked={views.terminal}    label="Terminals"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvaudit' type="checkbox" onClick={viewsHandler} checked={views.audit}    label="Audit Report"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvgrep' type="checkbox" onClick={viewsHandler} checked={views.general}   label="General Reports"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvzinara' type="checkbox" onClick={viewsHandler} checked={views.zinara}    label="Zinara"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvdelete' type="checkbox" onClick={viewsHandler} checked={views.delet}    label="Delete Record"/>
    </Form.Group>
    <Form.Group>
    <Form.Check id='chkvgs' type="checkbox" onClick={viewsHandler} checked={views.groupsettings}    label="Group Settings"/>
    </Form.Group>
    </Card.Body>
    </Card>
    </Col>
    </Row>

    </Card.Body>
    <Card.Footer>
      <Button id='btnsave' variant="info" onClick={saveRolesHandler}  size="sm" >SAVE PROFILE</Button>{' '}
      <Button id='btnrefresh' variant="outline-info" size="sm" onClick={refreshRoles}  >REFRESH</Button>{' '}
      <Button id='btnclear' variant="outline-info" size="sm" onClick={clearRoles}  >CLEAR</Button>{' '}

    </Card.Footer>
    </Card>
    </Col>
    </Row>
      </Form>
    </Container>
  );
}

export default Profiles;
