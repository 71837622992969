import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';


function ZinaraUsd(...props){

    const [lightvehicles,setLightVehicles] = useState('');
    const [heavyvehicles,setHeavyVehicles]= useState('');
    const [minibuses,setMiniBuses] = useState('');
    const [buses,setBuses] = useState('');
    const [trucks,setTrucks] = useState('');
    const [id,setId] = useState(0);
    const [firstrun,setFirstRun]=useState(true);
    const [gtariff,setGTariff] = useState('2');
    const [tariffchanged,setTariffChanged] = useState(false);
    const [tlist,setTlist] = useState([]);

    const gtrefresh = async (silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/gettgnames';
     const data ={};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
            clearLiteHandler();
            setId('0');
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("refreshing Tariff group Name data: "+JSON.stringify(res.data.data));
            const f = res.data.data;
                setTlist(f,()=>{
                    //todo: populate on UI
                });
                
            
         }
     });
    }
    const refresh =(silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/gettollfees';
     const data ={curr:'840',gtariff:gtariff};
     Axios.post(url,data).then((res)=>{
        console.log("Received mesage here: "+res.data.msg);
         if(res.data.msg==='ko'){
            clearLiteHandler();
            setId('0');
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            setFirstRun(false);
            const f = res.data.data;
            setId(f.id);
            setLightVehicles((Number(f.lv)/100).toFixed(2));
            setHeavyVehicles((Number(f.hv)/100).toFixed(2));
            setMiniBuses((Number(f.mb)/100).toFixed(2));
            setBuses((Number(f.cb)/100).toFixed(2));
            setTrucks((Number(f.ht)/100).toFixed(2));
         }
     });
    }
    //to do this on every page render
   
useEffect(()=>{ 
    window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
    async function refreshdata(){
        console.log("getting toll fees here!!");
        await gtrefresh(true);
        await refresh(true);
        console.log("toll data: "+lightvehicles);
      };
      if(firstrun || tariffchanged===true){
        refreshdata();
        setTariffChanged(false);
      }
},[tariffchanged]);


    const blurHandler = (e)=>{
        const num = e.target.value
        var disp = parseFloat(num);
        var val = Number(disp).toFixed(2);
        switch(e.target.id){
            case 'ulv':
                setLightVehicles(val);
                break;
            case 'uhv':
                setHeavyVehicles(val);
                break;
            case 'umb':
                setMiniBuses(val);
                break;
            case 'ucb':
                setBuses(val);
                break;
            case 'uht':
                setTrucks(val);
                break;
                default:
                    break;  
        }
    }

    const feesHandler = (e)=>{
        const val = e.target.value;
       /* const num = e.target.value
        var disp = parseFloat(num);
        var val = disp.toFixed(2);*/
        switch(e.target.id){
            case 'ulv':
                setLightVehicles(val);
                break;
            case 'uhv':
                setHeavyVehicles(val);
                break;
            case 'umb':
                setMiniBuses(val);
                break;
            case 'ucb':
                setBuses(val);
                break;
            case 'uht':
                setTrucks(val);
                break;
                default:
                    break;

        }
    }
    const updatetollfees =()=>{
     if(lightvehicles.length===0 || heavyvehicles.length===0 || minibuses.length===0 || buses.length===0 || trucks.length===0){
         ShowMsg("Please Enter All Toll Fees, As Expected!!",false);
         return;
     } 
     const idd = sessionStorage.getItem('ndiani');
     var url =Url+'/updatetollfees';
     const lv = (parseFloat(lightvehicles)*100)+"";
     const hv = (parseFloat(heavyvehicles)*100)+"";
     const mb = (parseFloat(minibuses)*100)+"";
     const cb = (parseFloat(buses)*100)+"";
     const ht = (parseFloat(trucks)*100)+"";
    console.log("Value of ID before sending: "+id);
     const data ={id:id, curr:'840',gtariff:gtariff,lv:lv,hv:hv,mb:mb,cb:cb,ht:ht};
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ok'){
             ShowMsg(res.data.pop,true);
         }else{
             ShowMsg(res.data.pop,false);
         }
     })
    }

   

   

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnupdate':
          updatetollfees();
          break;
          case 'btnrefresh':
         refresh(false);
          break;
          default:
        }
    }
    const clearLiteHandler  = ()=>{
        setLightVehicles('');
        setHeavyVehicles('');
        setMiniBuses('');
        setBuses('');
        setTrucks('');
 }  

    const groupTariffHandler = (e)=>{
        var val = e.target.value;
        clearLiteHandler();
        setGTariff(val);
        setTariffChanged(true);
      }
   
    return(
        <Container className="mt-2" fluid>
    <Row className="pt-2 ">
    <Col  sm={8}>
    <Card className=" pb-1 pt-2">
    <Card.Body>
    <Form>
    <Form.Group >
    <Form.Label>Zinara Tariff Groups</Form.Label>
    <Form.Select id='gtariff' size="sm" onChange={groupTariffHandler} value={gtariff} custom >
    <option>Select Tariff Group</option>
    {           
    tlist.map((opt)=>(
    <option value={opt.id}>{opt.name}</option>
    ))
    }
    </Form.Select>
    </Form.Group>
    <Form.Group>
    <Form.Label>Light Vehicles</Form.Label>
    <Form.Control id='ulv' autoComplete={'nope3'} type="number" placeholder="Light Vehicles..." value={lightvehicles} onChange={feesHandler} onBlur={blurHandler} />
    </Form.Group>
    <Form.Group>
    <Form.Label>Mini Buses</Form.Label>
    <Form.Control id='umb' autoComplete={'nope3'} type="number" placeholder="Mini Buses..." value={minibuses} onChange={feesHandler} onBlur={blurHandler} />
    </Form.Group>
    <Form.Group>
    <Form.Label>Buses</Form.Label>
    <Form.Control id='ucb' autoComplete={'nope3'} type="number" placeholder="Buses..." value={buses} onChange={feesHandler} onBlur={blurHandler} />
    </Form.Group>
    <Form.Group>
    <Form.Label>Heavy Vehicles</Form.Label>
    <Form.Control id='uhv' autoComplete={'nope3'} type="number" placeholder="Heavy Vehicles..." value={heavyvehicles} onChange={feesHandler} onBlur={blurHandler} />
    </Form.Group>
    <Form.Group>
    <Form.Label>Haulage Trucks</Form.Label>
    <Form.Control id='uht' autoComplete={'nope3'} type="number" placeholder="Haulage Trucks..." value={trucks} onChange={feesHandler} onBlur={blurHandler} />
    </Form.Group>
    </Form>
    </Card.Body>
    <Card.Footer>
    <Button id='btnupdate' variant="info" onClick={btnHandler} size="sm" >UPDATE TOLL FEES</Button>{' '}
      <Button id='btnrefresh' variant="outline-info" size="sm" onClick={btnHandler} >REFRESH</Button>{' '}
    </Card.Footer>
    </Card>
    </Col>
    </Row>
    </Container>
    )


}
export default ZinaraUsd;