import React from 'react';
import {ProgressBar,Tabs,Tab,Form,Button,Modal,Card,Row,Container,Col} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import Swal from 'sweetalert2'
import Axios from 'axios'
import FileInput from './file';
import {ShowMsg,Msg} from './pop';
import {Url} from './url';
import {MSGS} from './constants/msg';
import {showSpinDiag} from './spin';
import {EventAllowed} from './profilecfg';
import {ROLEOPS,USER_PROF,LoggedInUser} from './constants/constants';
import Dropzone from "dropzone";
import Papa from 'papaparse';
class Terminals extends React.Component{
  constructor(props){
    super(props);
    this.state = {
        stid : '',
        /*zwltid:'',
        zigtid:'',
        usdtid:'',*/
        mid: '',
        cardaccepterid:'',
        ptid:'',
        commstype:4,
        model:1,
        id:0,
        sid:0,
        fid:0,
        csvtoggle:false,
        options:[],
        foptions:[],
        moptions:[],
        mids:[],
        opts:'',
        roles:{},
        softlist:[],
        firmlist:[],
        mall:[],
        showprog:false,
        ismulti:false,
      }
    }

    async componentDidMount() {
     document.addEventListener('contextmenu',(e)=>{
      e.preventDefault();
     });
     /*document.addEventListener('click',(e)=>{
      e.stopPropagation();
      if(e.ctrlKey){
        console.log("Ctrl key pressed, preventing default here");
      }
      
      e.preventDefault();
     });*/
     /*window.onkeydown = (e)=>{
      if(e.ctrlKey === true){
        e.preventDefault();
      }
     }*/
     window.onkeydown = (e)=>{
      if(e.ctrlKey === true || e.metaKey === true){
        if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
        e.preventDefault();
      }
     }
     console.log('terminals Component DID MOUNT!')
     await this.getterminals();
     await this.getmerchants();
     await this.GetAppsHandler();
     await this.GetOTAsHandler();
     await this.GetRolesHandler();
      //this.setState({roles: await this.GetRolesHandler()});
   }

   async componentDidUpdate(prevProps, prevState) {
      console.log('Services Component DID UPDATE!')
      //this.setState({mid:this.props.mid});
      if(prevState.options !=null  && this.state.options == null){
          console.log('Previous options are null!! getting values')
          await   this.getterminals();
          await this.getmerchants();
          await this.GetAppsHandler();
          await this.GetOTAsHandler();
          await this.GetRolesHandler();
          window.onkeydown = (e)=>{
            if(e.ctrlKey === true){
              e.preventDefault();
            }
           }
          //this.setState({roles: await this.GetRolesHandler()});
      }else{
          console.log('options already received');
      }




   }

   GetOTAsHandler = async () =>{
    var data={};
    var url =Url+'/getfirmwaregrps';
   // console.log("geroles url: "+url);
 console.log('Doing Get firmwares POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   //console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('firmware grps data received OK!! firmware list size: '+res.data.otas.length);
       this.setState({firmlist:res.data.otas});
       let otas=[];
       var list =res.data.otas;

   list.map((ota)=>{
       let option={};
       option.value=ota.id;
       option.text=ota.name;
       otas.push(option);
       return 0;
   }
 )
   this.setState({foptions:otas});
    
     }else{
      console.log("Failed to get firmware grp list!!");
     }

   
    });
   }

   GetAppsHandler = async () =>{
    var data={};
    var url =Url+'/getsoftwaregrps';
   // console.log("geroles url: "+url);
 console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   //console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('software grps data received OK!! apps size: '+res.data.apps.length);
       this.setState({softlist:res.data.apps});
    
     }else{
      console.log("Failed to get software grp list!!");
     }

   
    });
   }


   GetRolesHandler = async () =>{
    var data={roleid: sessionStorage.getItem('role')};
    var url =Url+'/getroles';
    console.log("geroles url: "+url);
 console.log('Doing Get roles POST here!!! roleID: '+sessionStorage.getItem('role'));
 Axios.post(url,data).then((res)=>{
   //console.log('get roles Response received: '+JSON.stringify(res.data));
   if(res.data.msg==='ok'){
       console.log('profile data received OK!!');
       const r ={entries:res.data.roles[0],changes:res.data.roles[1],views:res.data.roles[2]};
       this.setState({roles:r},()=>{
        console.log("roles added ok!!!");
       });
    
     }else{
       this.clearHandler();
     }

   
    });
   }

   getmerchants1 = async ()=>{
    var url =Url+'/getmerchs';
    var data = {};
   var list;
    console.log('getting merchants POST here!!!');
    Axios.post(url,data).then((res)=>{
      console.log('Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Terminals received  OK!! received note: ');
          list = res.data.apps;
 //  console.log("received Branch Datalist: "+JSON.stringify(list));
   let apps=[];

   list.map((app)=>{
       let option={};
       option.value=app.id;
       option.text=app.name;
       apps.push(option);
   }
 )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
   this.setState({options:apps});
 }

});
  }

  getmerchants = async ()=>{
    var url =Url+'/getmerchants';
    var data = {};
   var list;
    console.log('getting merchants POST here!!!');
    Axios.post(url,data).then((res)=>{
      console.log('Merchants Response Response received: '+res.data.msg);
      if(res.data.msg==='ok'){
          console.log('Merchants received  OK!! received note: all: '+JSON.stringify(res.data.all));
          list = res.data.mids;
          this.setState({mall:res.data.all});
   console.log("received merch Datalist: "+JSON.stringify(list));
   let merchs=[];

   list.map((m)=>{
       let option={};
       option.value=m.id;
       option.text=m.name;
       merchs.push(option);
   }
 )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
   this.setState({moptions:merchs});
 }

});
  }

   getterminals = async ()=>{
     var url =Url+'/getsoftwaregrps';
     var data = {};
    var list;
     console.log('getting terminals POST here!!!');
     Axios.post(url,data).then((res)=>{
       console.log('Response Response received: '+res.data.msg);
       if(res.data.msg==='ok'){
           console.log('Terminals received  OK!! received note: ');
           list = res.data.apps;
  //  console.log("received Branch Datalist: "+JSON.stringify(list));
    let apps=[];

    list.map((app)=>{
        let option={};
        option.value=app.id;
        option.text=app.name;
        apps.push(option);
    }
  )
//  console.log("on Startup: branch options: "+JSON.stringify(merchants));
    this.setState({options:apps});
  }

});
   }

  isvalidatedOk =()=>{
    console.log(">>this.state: "+JSON.stringify(this.state));
    if(this.state.ptid.length===0 || this.state.stid.length===0 || this.state.mid.length===0 || this.state.commstype===0 || this.state.model===0 || this.state.sid===0)
        return false;
        return true;
  }

  handleChange =(e)=>{

    this.setState({commstype:e.target.value})

  }

  handlemidChange =(e)=>{

    this.setState({mid:e.target.value})

  }

  modelHandler =(e)=>{

    this.setState({model:e.target.value})

  }

  softwaregrpHandler =(e)=>{

    this.setState({sid:e.target.value})

  }

  firmwaregrpHandler =(e)=>{

    this.setState({fid:e.target.value})

  }

saveHandler =()=>{
 const {roles,id} = this.state;
  if(id===0 && !EventAllowed(roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_INSERT,false);
    return;
  }

  if(id > 0 && !EventAllowed(roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_EDIT,false);
    return;
  }

  if(this.state.id === 0 && !EventAllowed(this.state.roles.entries,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_INSERT,false)
    return
  }
  if(this.state.id > 0 && !EventAllowed(this.state.roles.changes,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_EDIT,false)
    return
  }

  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg('Please fill up all required fields!!',false);
    return;
  }
  var url =Url+'/addterminal';
  var data = this.state;

  console.log('Doing Terminal add POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('Response Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal saved OK!! received note: '+res.data.pop);
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Failed to save terminal data!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

findMerchFromList =async(t)=>{
  var m = this.state.mall.find(o=>o.mid === t.mid);
  return m;
}


clearHandler =()=>{
  this.setState({ptid:'',stid:'',zwltid:'',zigtid:'',usdtid:'',mid:'',commstype:4,id:0,model:0,sid:0,cardaccepterid:'',ismulti:false});
}

findHandler =()=>{
  if(!EventAllowed(this.state.roles.views,ROLEOPS.TERMINAL)){
    ShowMsg(Msg.TERMINAL_FIND,false)
    return
  }
    if(this.state.ptid.length===0 && this.state.stid.length===0 ){
    ShowMsg("Please Enter Tid or Serial Number for record search to proceed!!")
    return;
  }
  var data={ptid:this.state.ptid, stid: this.state.stid};
     var url =Url+'/getterminal';
  console.log('Doing Terminal find POST here!!!');
  Axios.post(url,data).then(async (res)=>{
    console.log('Terminal Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Found!! terminal: '+JSON.stringify(res.data.terminal));
        var t = res.data.terminal;
        console.log("All merchants: "+JSON.stringify(this.state.mall));
        console.log("Current Terminal: "+JSON.stringify(t));
        //var m = this.state.mall.find(o=>o.mid === t.mid);
        var  m = await this.findMerchFromList(t);
        if(m !== null || m !== undefined){
          console.log("Merchant record: "+JSON.stringify(m));
         this.setState({ismulti:m.multicurr});
        }
        this.setState(res.data.terminal);

        console.log("new Terminal system id: "+  this.state.id);
        //ShowMsg(res.data.pop,true);
    }else{
      console.log('Could not find terminal data!!!')
      ShowMsg(res.data.pop,false);
    }
  });

}

ptidHandler =(e)=>{
  this.setState({ptid: e.target.value});
}

usdtidHandler =(e)=>{
  //this.setState({usdtid: e.target.value});
}

zwltidHandler =(e)=>{
  //this.setState({zwltid: e.target.value});
}

zigtidHandler =(e)=>{
  //this.setState({zigtid: e.target.value});
}

stidHandler =(e)=>{
  this.setState({stid: e.target.value});
}

midHandler =(e)=>{
  this.setState({cardaccepterid: e.target.value});
}

activateHandler =()=>{
  const roleid = sessionStorage.getItem('role');
  console.log("loggedin User id: "+roleid);
  if(!this.isvalidatedOk()){
    //todo add msgbox
    ShowMsg('Please fill up all required fields before exporting!!',false);
    return;
  }
  
  var data={ptid:this.state.ptid, stid: this.state.stid};
     var url =Url+'/exportterminal';
  console.log('Doing Terminal export POST here!!!');
  Axios.post(url,data).then((res)=>{
    console.log('Terminal Find Response received: '+res.data.msg);
    if(res.data.msg==='ok'){
        console.log('Terminal Export OK!!');
        ShowMsg(res.data.pop,true);
    }else{
      console.log('Terminal Export failed!!!')
      ShowMsg(res.data.pop,false);
    }
  });
}

togglecsv =()=>{
  this.setState({csvtoggle:!this.state.csvtoggle});
}

csvupload = ()=>{
  this.togglecsv(); 
 
}

processcsvdata =(data)=>{
  this.togglecsv();
  this.setState({showprog:true},async()=>{
    var bulk=[];
    var tmp={};
    var found=false;
    const size = data.length-2;
    const title = data[0];
    const {mall}=this.state;
    console.log("Received merchants: "+JSON.stringify(mall));
    for(var i=1; i<size; i++){
      tmp={};
      tmp["id"]="0";
      tmp["model"]="1";
      tmp["commstype"]="4";
      for(var j =0; j<title.length; j++){
        //console.log("current heading: "+title[j])
        if(title[j]=== "merchantid" ){
          
          for(var k=0; k< mall.length; k++){
            if(data[i][j]===mall[k].mid){
              tmp["mid"]=mall[k].id+"";
              found=true;
              break;
            }
          }
          if(!found){
            ShowMsg('Please note that Merchant ID: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
              return;
          }
        }/*else if(title[j]==="cardsgroup"){
          found=false;
          const {cards}=this.state;
              for(var k =0; k< cards.length; k++){
                console.log("service from file: "+data[i][j].trim().toLowerCase()+" Service from DB: "+cards[k].name.toLowerCase());
                if(data[i][j].trim().toLowerCase()===cards[k].name.toLowerCase()){
                tmp["cid"]=cards[k].id+"";
                found=true;
                break;
                }
              }
              if(!found){
                ShowMsg('Please note that Cards Group: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
                  return;
              }
        }*/else if(title[j]==="softwaregroup"){
          found=false;
          const {softlist}=this.state;
              for(var k =0; k< softlist.length; k++){
                console.log("Apps group name from file: "+data[i][j].trim().toLowerCase()+" Apps group name from DB: "+softlist[k].name.toLowerCase());
                if(data[i][j].trim().toLowerCase()===softlist[k].name.toLowerCase()){
                tmp["sid"]=softlist[k].id+"";
                found=true;
                break;
                }
              }
              if(!found){
                ShowMsg('Please note that Software Group: '+data[i][j]+' is not known. Try to check again your upload file for errors from line '+(i+1)+'!!',false);
                  return;
              }
        }else if(title[j]==="serial"){
          tmp["ptid"]=data[i][j]+"";
        }        
        else
        tmp[title[j]+""]=data[i][j]+"";
      } 
      //console.log("tmp.serial: "+tmp.serial);
      bulk.push(tmp);
      //for (var member in tmp) delete tmp[member];  
    }
    /*if(true)
    return;*/
    console.log("final formated data to send: "+JSON.stringify(bulk));
    var url =Url+'/bulkaddterminal';
    if(bulk.length===0){
      ShowMsg('Please use csv file with atleast one Terminal!!',false);
      return;
    }
    console.log('Doing Terminal add POST here!!!');
    Axios.post(url,bulk).then((res)=>{
      console.log('Response Response received: '+res.data.msg);
      this.setState({showprog:false},()=>{
        if(res.data.msg==='ok'){
          console.log('Terminal saved OK!! received note: '+res.data.pop);
          ShowMsg(res.data.pop,true);
      }else{
        console.log('Failed to save terminal data!!!')
        ShowMsg(res.data.pop,false);
      }
      });
      
    });
  });
 
  
}

    render(){
     const {showprog,mall}=this.state;
     return(
        <Container  className="pb-4" fluid>
       <Row className='pb-4 mb-4'>
       <Col  sm={6}>
       <Card>
       <Card.Header><span className="App-header shadow-sm">Terminal Configurations</span></Card.Header>
       <Card.Body>
       <Form>
       <Form.Group>
      <Form.Label>Firmware Group</Form.Label>
      <Form.Select size="sm" onChange={this.firmwaregrpHandler} value={this.state.fid} custom >
         <option value='0'>Firmware Groups</option>
         {
           this.state.foptions.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }

      </Form.Select>
      </Form.Group>
       <Form.Group>
      <Form.Label>Software Group</Form.Label>
      <Form.Select size="sm" onChange={this.softwaregrpHandler} value={this.state.sid} custom >
         <option value='0'>Software Groups</option>
         {
           this.state.options.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }

      </Form.Select>
      </Form.Group>
       <Form.Group>
       <Form.Label>Terminal Serial Number</Form.Label>
       <Form.Control type="text" placeholder="Serial Number...." value={this.state.ptid} onChange={this.ptidHandler}/>
       </Form.Group>
        <Form.Group>
       <Form.Label>Terminal Model</Form.Label>
       <Form.Select size="sm" onChange={this.modelHandler} value={this.state.model} custom >
         <option value='0'>Terminal Model</option> 
          <option selected value='1'>CS10</option>
         {/* <option value='2'>PAX S920</option>
          <option value='3'>PAX Q80</option>
          <option value='4'>PAX A60</option>
          <option value='5'>PAX A80</option>*/}
       </Form.Select>
       </Form.Group>
       <Form.Label>Merchant Group Name</Form.Label>
       <Form.Select size="sm" onChange={this.handlemidChange} value={this.state.mid} custom >
         <option value='0'>Merchant Groups</option>
         {
           this.state.moptions.map((opt)=>(
             <option value={opt.value}>{opt.text}</option>
           ))
         }
       </Form.Select>
       <Form.Group>
       <Form.Label>Card Acceptor ID</Form.Label>
       <Form.Control type="text" placeholder="Card Acceptor ID...." value={this.state.cardaccepterid} onChange={this.midHandler}/>
       </Form.Group>
       <Form.Group>
       <Form.Label>Default Terminal ID</Form.Label>
       <Form.Control type="text" placeholder="Default Terminal ID...." value={this.state.stid} onChange={this.stidHandler}/>
       </Form.Group>  
      {/* <Form.Group hidden={true}>
       <Form.Label>ZWL Terminal ID</Form.Label>
       <Form.Control type="text" placeholder="ZWL Terminal ID...." value={this.state.zwltid} onChange={this.zwltidHandler}/>
       </Form.Group> 
       <Form.Group hidden={true}>
       <Form.Label>ZIG Terminal ID</Form.Label>
       <Form.Control type="text" placeholder="ZIG Terminal ID...." value={this.state.zigtid} onChange={this.zigtidHandler}/>
       </Form.Group> 
       <Form.Group hidden={true}>
       <Form.Label>USD Terminal ID</Form.Label>
       <Form.Control type="text" placeholder="USD Terminal ID...." value={this.state.usdtid} onChange={this.usdtidHandler}/>
       </Form.Group> */}
       <Form.Group>
       <Form.Label>Comms Type</Form.Label>
       <Form.Select size="sm" onChange={this.handleChange} value={this.state.commstype} custom >
         <option value='0'>Comms Type</option>
          <option value='1'>GPRS</option>
          <option value='2'>WIFI</option>
          <option value='3'>ETHERNET</option>
          <option selected value='4'>GPRS,WIFI</option>
          <option value='5'>WIFI,ETHERNET</option>
          <option value='6'>GPRS,WIFI,ETHERNET</option>
       </Form.Select>
       </Form.Group>
       </Form>
       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={this.saveHandler}>SAVE TERMINAL</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.findHandler}>FIND</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.clearHandler}>CLEAR</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.activateHandler}>EXPORT TERMINAL</Button>{' '}
       <Button variant="outline-info" size="sm" onClick={this.csvupload}>BULK UPLOAD TERMINALS</Button>{' '}
       <ProgressBar className={'top-5'} hidden={mall.length!==0}  animated variant={'info'} label={'PLEASE WAIT LOADING BACKGROUND TASKS...'} now={100} />
       <ProgressBar className={'top-5'} hidden={!showprog}  animated variant={'danger'} label={'UPLOADING TERMINAL RECORDS PLEASE WAIT...'} now={100} />
       </Card.Footer>
       </Card>
       </Col>
       <Col sm={6}>
       </Col>
       </Row>
       <Modal  show={this.state.csvtoggle}
       backdrop="static"
       keyboard={false}
       onEntered ={(e)=>{
        let uploadform ={
          autoProcessQueue: false,
          uploadMultiple: true,
          parallelUploads: 100,
          maxFiles: 100, 
        }
        let myDropzone = new Dropzone("#csv",{options:{uploadForm:uploadform}});
        myDropzone.options.autoProcessQueue=false;
       
       myDropzone.on("addedfile", file => {
       
         console.log(`File added: ${file.name}`);
         this.setState({showprog:true},async()=>{
          console.log("please wait now showing");
          Papa.parse(file,{
            complete:(results)=>{
              console.log("csv record size: "+results.data.length);
              console.log("CSV parse done: data: "+JSON.stringify(results.data));
              this.processcsvdata(results.data);
            }
           })
        });
        
       });}}
       >
<Modal.Header id='csvupload'  closeButton onClick={this.togglecsv}>
      <Modal.Title >Merchants Bulk Upload</Modal.Title>
      </Modal.Header> 
      <Modal.Body className=" pb-1 pt-2 sform">
      <React.Fragment>
        <Form id={'csv'} className="dropzone" action={'/'} >
        <div > Upload csv File here</div>
        </Form>
        
         </React.Fragment>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={this.togglecsv} >CLOSE</Button>{' '}

      </Modal.Footer>   

</Modal>
       </Container>
     )
   }

  }
  export default Terminals;
