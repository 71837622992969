import {useState,useEffect} from 'react';
import React from 'react';
import {Button,Form,Card,Row,ListGroup,Container,Col} from 'react-bootstrap';
import {ConfirmMsg, ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';
import {timeout} from './wait';
import {showSpinDiag} from './spin';
import { Redirect } from "react-router";
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter,selectFilter  } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider,{CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { formatDate, toDate } from 'date-utils-2020'

function TerminalMushumo(...props){
  const [firstrun,setFirstRun]=useState(true);
  const [mushumo,setMushumo]=useState([]);

  const [page,setPage]=useState(1);
  const [data,setData]=useState([]);
  const [sizePerPage,setSizePerPage]=useState(10);
  const [spin,setSpin]=useState(true);
  const [options,setOptions]=useState([]);

    const products =[{sgm:'PAYMENT-PILOT-6.0.3',ptid: 'OK Zimbabwe',stid:'OK East Gate',cardaccepterid:'10000001078',mgn:'AFC MAIN MERCHANT'},
    {sgm:'PAYMENT-PILOT-6.0.3',ptid: 'OK Zimbabwe',stid:'OK East Gate',cardaccepterid:'10000001078',mgn:'AFC MAIN MERCHANT'},
                    ];
     const columns =[{
        dataField:'sgm',
        text:'Software Group Version', 
       headerStyle: (colum, colIndex) => {
            return { width: '250px', textAlign: 'center' };
          },
        filter: selectFilter({
          options: options
        })
     },{
        dataField:'ptid',
        text:'Serial No.',
        sort: true
     },{
        dataField:'stid',
        text:'Terminal ID',
        headerStyle: (colum, colIndex) => {
            return { width: '220px', textAlign: 'center' };
          },
        filter: textFilter()
     },{
        dataField:'cardaccepterid',
        text:'Card Accepter ID', 
        sort: true 
     },{
        dataField:'mgn',
        text:'Merchant Group Name', 
        sort: true ,
        headerStyle: (colum, colIndex) => {
            return { width: '250px', textAlign: 'center' };
          },
        filter: textFilter()
     },{
      dataField:'created',
      text:'Creation Date', 
      sort: true 
   }]

     
    

    const mrefresh = ()=>{
      setMushumo([]);
      refresh(false);
    }

    const getSoftwareOptions = async ()=>{
      var url =Url+'/getsoftwaregrps';
      Axios.post(url,data).then((res)=>{
        if(res.data.msg==='ko'){
         console.log("Failed app list here!!");
           setOptions([]);
        }else{
          const list = res.data.apps;
           console.log("received apps: "+JSON.stringify(list));
           var apps =[];
           
           for(var i=0; i<list.length; i++){
            //console.log("App: "+JSON.stringify(list[i]));
            const name = list[i].name;
            const label= i+"";
              apps.push({label:name});
           }
           console.log("Options: "+JSON.stringify(apps));
           setOptions(apps);
        }
       
    });
    }

    const refresh = async (silent)=>{
        //todo: to query toll data here!!
        var url =Url+'/getterminalsreport';
     const data ={};
     setFirstRun(false);
     setSpin(true);
     await getSoftwareOptions();
     Axios.post(url,data).then((res)=>{
         if(res.data.msg==='ko'){
          console.log("Failed terminals report here!!");
            if(silent===false)
             ShowMsg(res.data.pop,false);
         }else{
            console.log("received terminal mushumo: "+JSON.stringify(res.data.terminals));
            setMushumo(res.data.terminals);
            
         }
         setSpin(false);
     });
  
    }
    //to do this on every page render
   
useEffect(()=>{ 
  /*window.onkeydown = (e)=>{
    if(e.ctrlKey === true){
      e.preventDefault();
    }
   }*/

   window.onkeydown = (e)=>{
    if(e.ctrlKey === true || e.metaKey === true){
      if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
      e.preventDefault();
    }
   }
    async function refreshdata(){
        console.log("getting terminals report here!!");
        await refresh(true);
        //console.log("toll data: "+lightvehicles);
      };
      if(firstrun === true){
        refreshdata();
      }
},[firstrun]);


  

    const btnHandler = (e)=>{
        switch(e.target.id){
          case 'btnupdate':
          //updatetollfees();
          break;
          case 'btnrefresh':
         refresh(false);
          break;
          default:
        }
    }
    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button className="btn btn-info" onClick={ mrefresh }>Refresh</button>{' '}
          <button className="btn btn-outline-info" onClick={ handleClick }>Export to CSV</button>
          
        </div>
      );
    };
    const { ExportCSVButton } = CSVExport;

    const filename =()=>{
      const dt =formatDate(new Date(),'yyyy-MM-dd_hh:mm:ss');
      console.log("current date: "+dt);
      return 'TerminalReport_'+ dt;
    }
   
    return(
        <Container className="mt-2" fluid>
            <ToolkitProvider
            keyField="id"
            data={ mushumo }
            columns={ columns }
            exportCSV={{
              fileName:'TerminalsReport_'+formatDate(new Date(),'yyyy-MM-dd_hh:mm:ss')+'.csv',
              onlyExportFiltered: true, exportAll: false
            }}
          >
          {
            props => (
              <div>
               
               
                <BootstrapTable
                { ...props.baseProps }         
                    filter={filterFactory()}
                    pagination={ paginationFactory() }
                    striped
                    hover
                    condensed
                    />
                    {showSpinDiag(spin,'Fetching Terminal record(s)')}
                    <hr />  
                  <button className="btn btn-info" onClick={ mrefresh }>Refresh</button>{' '}
                <ExportCSVButton className="btn btn-outline-info" { ...props.csvProps }>Export to CSV</ExportCSVButton>   
                
              
              </div>
            )
          }
      </ToolkitProvider>
            
    </Container>
    )


}
export default TerminalMushumo;