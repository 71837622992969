import React, {useState,useEffect,useRef} from 'react';
import {Tabs,Tab,Modal,Form,Button,InputGroup,FormControl,Card,Row,Container,Col,ListGroup} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import {ShowMsg} from './pop';
import Axios from 'axios';
import {Url} from './url';

function CardSettings(...props){
    const [cardGroupList,setCardGroupList]= useState([]);
    const cardsRef = useRef(cardGroupList);
    const [showHide,setShowHide] = useState(false);
    const [gtoggle,setgtoggle]= useState(false);
    const [newcardname,setNewcardName] = useState('');
    const [binRange,setBinRange] = useState('');
    const [cardid,setCardId] = useState(0);
    const [card,setCard]=useState({id:0,name:'',binrange:'',sale:false,cashback:false,balance:false,cardactivation:false,setpin:false,billpayment:false,mobilepayment:false,cash:false,internal:false,zinara:false,cid:0});
    const [pin,setPin]=useState({id:0,cid:0,sale:true,cashback:true,balance:true,cardactivation:true,setpin:true,billpayment:true,mobilepayment:false,cash:true,internal:false,zinara:true});
    const [service,setService]=useState({id:-1,name:''});
    const [soptions,setsOptions]=useState([]);
    const [pins,setPins]=useState([]);
    const [cardtitle,setCardTitle]=useState('No Card Group Configured')
    const [cardsgrouplist,setCardsGroupList]=useState([]);//{id:'',name:'',sid:0}
    const [cgrp,setCGrp]=useStateWithCallbackLazy({id:0,name:'',sid:0});
    const [hwandisa,setHwandisa]=useState(true);
    const [ctitle,setctitle]=useState('No Selected Card List')
    const [sindex,setsindex]=useState('-1');
    useEffect(()=>{
      window.onkeydown = (e)=>{
        if(e.ctrlKey === true || e.metaKey === true){
          if(!(e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 88))
          e.preventDefault();
        }
       }
        if(cardGroupList.length === 0){
            console.log('Cards scheme length: '+ cardGroupList.length); 
        getCards();
        getSevices();
        }else{
            console.log('Else Cards scheme length: '+ cardGroupList.length);
        }
      },[cardGroupList]);
    const cardGroupInfoHandler = (e)=>{
        //todo: to add code to pop up cardgroup info
        e.preventDefault();
    var id = e.target.value;
    const card = cardGroupList[id];
    console.log("Current Card: "+JSON.stringify(card));
    setCard(card);
    
    setCardId(card.id);
    setNewcardName(card.name);
    setBinRange(card.binrange);
    toggleModal();
    }

    const addcardscheme =(e)=>{
      e.preventDefault();
      toggleModal();
    }

    const cardsGroupInfoHandler = (e)=>{
      //todo: to add code to pop up cardgroup info
      e.preventDefault();
      var id = e.target.value;
      const card = cardsgrouplist[id];
      console.log("cgrp data##: "+JSON.stringify(card));
      setCGrp(card);
      setctitle('Current Cards Group List: '+card.name);
      setHwandisa(false);
      console.log("cgrp data: "+JSON.stringify(card));
      var url =Url+'/getcardschemes'
          Axios.post(url,{
            cid:card.id,
            sid:card.sid,          
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                console.log('Service data: '+JSON.stringify(res.data.service));
                if(res.data.service !== null)
                setService(res.data.service)
                setCardGroupList(res.data.cards);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
  }

  const cls =()=>{
    setCard({id:0,name:'',binrange:'',sale:false,cashback:false,balance:false,cardactivation:false,setpin:false,billpayment:false,mobilepayment:false,cash:false,internal:false,zinara:false,cid:0});
  }
    const toggleModal =()=>{
      setsindex('-1');
      if(showHide)
      cls();
        setShowHide(!showHide);
    }
    const toggleGModal =()=>{
      setgtoggle(!gtoggle);
  }

    const addcardHandler =()=>{
        setNewcardName('');
        setBinRange('');
        setCGrp({...cgrp,sid:0,name:''});
        setgtoggle(true);
        setCardId(0);
    }
    const newCardNameHandler =(e)=>{
      setCard({...card,name:e.target.value});
    }
    const newCardsNameHandler =(e)=>{
      setCGrp({...cgrp,name:e.target.value});
    }

    const getSevices =()=>{
      var url =Url+'/getservicegrps'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('ServicessResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                console.log('Services data: '+JSON.stringify(res.data.services));
                setsOptions(res.data.services);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }
    const getcardschemes = ()=>{
      console.log("calling get cards here!!!");
      var url =Url+'/getcardschemes'
        Axios.post(url,{
           
        }).then((res)=>{
          console.log('Cardsscheme received: '+res.data.msg);
          if(res.data.msg==='ok'){
              console.log('Cardschemes Response received: '+ JSON.stringify(res.data.cards));
              setCardGroupList(res.data.cards);
              //setPins(res.data.pins);
          }else{
            ShowMsg(res.data.pop,false);          
          }
        });
  }
    const getCards = ()=>{
        console.log("calling get cards here!!!");
        var url =Url+'/getcardsgroup'
          Axios.post(url,{
             
          }).then((res)=>{
            console.log('CardsResponse received: '+res.data.msg);
            if(res.data.msg==='ok'){
                console.log('Cards Response received: '+ JSON.stringify(res.data.cards));
                setCardsGroupList(res.data.cards);
                //setPins(res.data.pins);
            }else{
              ShowMsg(res.data.pop,false);          
            }
          });
    }

    const binRangeHandler =(e)=>{
        setBinRange(e.target.value);
        setCard({...card,binrange:e.target.value});
    }

    const handlesidChange =(e)=>{
       var val = e.target.value;
       setCGrp({...cgrp,sid:val});
    }

    const handlecardsidChange = (e)=>{
      e.preventDefault();
      var val = e.target.value;
      const s = soptions[val];
      console.log("Selected service: "+JSON.stringify(s));
      setCard({...card,sid:s.id});
      setService(s);
      setsindex(val);
      //setCGrp({...cgrp,sid:val});
    }

    const cardHandler =(e)=>{
      var id = e.target.id;
      var s=false;
      if(e.target.checked)
      s=true;
      switch(id){
        case 'sale':
          setCard({...card,sale:s});
          break;
          case 'cbk':
            setCard({...card,cashback:s});
          break;
        case 'bal':
          setCard({...card,balance:s});
          break;
        case 'dep':
          setCard({...card,deposit:s});
          break;
        case 'spin':
          setCard({...card,setpin:s});
          break;
        case 'act':
          setCard({...card,cardactivation:s});
          break;
        case 'bill':
          setCard({...card,billpayment:s});
          break;
        case 'cash':
          setCard({...card,cash:s});
          break;
        case 'zinara':
          setCard({...card,zinara:s});
          break;
        case 'mobile':
          setCard({...card,mobilepayment:s});
        break;
          default:
      }

    }

    const popCardUi =()=>{
      setCard({...card,cid:cgrp.id,binrange:'',name:''});
      toggleModal();
     console.log("New Card: "+JSON.stringify(card));
    }

    const addRecord =()=>{
        if(card.name.length===0){
            ShowMsg('Please enter Card Group Name!!',false);
            return;
          }
          
          console.log("New Card Scheme data: "+JSON.stringify(card));
         // return;
          var url =Url+'/addcardscheme'
          Axios.post(url,card).then((res)=>{
            console.log('CardschemeResponse received: '+res.data.msg);
            setCardGroupList(res.data.cards);
            console.log("Received Cards list: "+JSON.stringify(res.data.cards));
            if(res.data.msg==='ok'){
                //console.log('Cards ID Response received: '+res.data.id);
              
                    if(card.id>0)
                    ShowMsg('Cards Group Updated Successfuly!!',true);
                    else
                    ShowMsg('Cards Group Added Successfuly!!',true);
                    
            }else{
              ShowMsg(res.data.pop,false);
             
              
            }
            toggleModal();
            //setCard({});
            cls();
            
          });
    }
    const editcardsHandler = (e)=>{
      e.preventDefault();
      var  i = e.target.value;
      setCGrp(cardsgrouplist[i]);
      console.log("Selected Index: "+i+" Currently Selected cgrp: "+JSON.stringify(cgrp));
      toggleGModal();
    }
    const addCardsRecord =()=>{
      if(cgrp.name.length===0){
          ShowMsg('Please enter Cards Group Name!!',false);
          return;
        }
        if(cgrp.sid===0){
          ShowMsg('Please Select Services!!',false);
          return;
        }

        var url =Url+'/addcardsgroup'
        Axios.post(url,cgrp).then((res)=>{
          console.log('CardsResponse received: '+res.data.msg);
          setCardsGroupList(res.data.cards);
          console.log("Received Cards list: "+JSON.stringify(res.data.cards));
          if(res.data.msg==='ok'){
              //console.log('Cards ID Response received: '+res.data.id);
            
                  if(card.id>0)
                  ShowMsg('Cards Group Updated Successfuly!!',true);
                  else
                  ShowMsg('Cards Group Added Successfuly!!',true);
                  
          }else{
            ShowMsg(res.data.pop,false);
           
            
          }
          toggleGModal();
        });
  }

return( 
<Container className='pb-4' fluid>
<Row>
<Col sm={6}>
<Card>
       <Card.Header><span className="App-header shadow-sm">Cards Group Configurations</span></Card.Header>
       <Card.Body >
       
        <React.Fragment >
         <ListGroup className="sform">
         <ListGroup.Item  variant="dark"><h6>Configured Cards Group List</h6></ListGroup.Item>
         {cardsgrouplist.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>cardsGroupInfoHandler(e)} onContextMenu={(e)=>editcardsHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
  

       </Card.Body>
       <Card.Footer className="text-muted">
       <Button variant="info" size="sm" onClick={addcardHandler}>NEW CARDS GROUP</Button>{' '}
       </Card.Footer>
</Card>
</Col>
<Col sm={6}>
<Card>
      
       <Card.Body >
     
        <React.Fragment >
         <ListGroup className="sform">
         <ListGroup.Item  variant="info">
           <Row>
             <Col sm={4}>
             <span hidden={hwandisa}><Button variant="outline-info" size="sm" onClick={popCardUi} >ADD CARD</Button>{' '}</span>
             </Col>
             <Col sm={8}>
             <span ><h6>{ctitle}</h6> </span>
             </Col>
         
           </Row>
           </ListGroup.Item>
         {cardGroupList.map((item, i)=>(
           <ListGroup.Item action value={i} onClick={(e)=>cardGroupInfoHandler(e)} variant='info'>
           {item.name}
           </ListGroup.Item>
         ))}
         </ListGroup>
         </React.Fragment>
         

       </Card.Body>
       <Card.Footer className="text-muted">
       
       </Card.Footer>
</Card>
</Col>
</Row>
<Modal  show={gtoggle}
       backdrop="static"
       keyboard={false}
      >
    <Modal.Header  closeButton onClick={toggleGModal}>
      <Modal.Title >New Cards Group</Modal.Title>
      </Modal.Header> 
      <Modal.Body>
      <Form>
      
          <Form.Group>
      <Form.Label>Service Group Name</Form.Label>
      <Form.Select size="sm" onChange={handlesidChange} value={cgrp.sid} custom >
        <option value='0'>Select Service Group</option>
        {
          soptions.map((opt)=>(
            <option value={opt.id}>{opt.name}</option>
          ))
        }
      </Form.Select>
      </Form.Group>  
      
          <Form.Group>
      <Form.Label>Cards Group Name</Form.Label>
      <Form.Control type="txt" placeholder="Cards Group Name...." value={cgrp.name} onChange={newCardsNameHandler}/>
      </Form.Group>
         
          </Form>   
    </Modal.Body>
    <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleGModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addCardsRecord} >SAVE RECORD</Button>

      </Modal.Footer>   

</Modal>

<Modal
       show={showHide}
       backdrop="static"
       keyboard={false}
       dialogClassName={"modal-40w"}>
      <Modal.Header  closeButton onClick={toggleModal}>
      <Modal.Title >New Card Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Row>
         
          <Col >
          <Form.Group>
      <Form.Label>Card Group Name</Form.Label>
      <Form.Control type="text" placeholder="Card Group Name...." value={card.name} onChange={newCardNameHandler}/>
      </Form.Group>
          </Col>
        </Row>
      <Row>
       <Col>
      <Form.Group>
      <Form.Label>BIN Range</Form.Label>
      <Form.Control type="text" placeholder="BIN Range: (400000-49999,600000-699999,....)" value={card.binrange} onChange={binRangeHandler}/>
      </Form.Group>
      </Col>
      </Row>
      <Row>
       <Col  sm={6}>
       <Form.Group>
       <span><h6>Card Sevices Allowed</h6></span>
       </Form.Group>
       </Col>
       </Row>
       <Row>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check id='sale' type="checkbox"  disabled={!service.sale?'disabled':null}  onClick={cardHandler} checked={card.sale} label="Purchase"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='cbk' type="checkbox" disabled={!service.cashback?'disabled':null} onClick={cardHandler} checked={card.cashback} label="Cashback"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='bal' type="checkbox" disabled={!service.balance?'disabled':null} onClick={cardHandler} checked={card.balance} label="Balance"/>
       </Form.Group>
       </Col>
       <Col  sm={4}>
       <Form.Group>
       <Form.Check id='dep' type="checkbox" disabled={!service.deposit?'disabled':null} onClick={cardHandler} checked={card.deposit} label="Deposit"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='spin' type="checkbox" disabled={!service.setpin?'disabled':null} onClick={cardHandler} checked={card.setpin} label="Setpin"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='cash' type="checkbox" disabled={!service.cash?'disabled':null} onClick={cardHandler} checked={card.cash} label="Cash Withdrawal"/>
       </Form.Group>
       </Col>
       <Col  sm={24}>
       <Form.Group>
       <Form.Check id='bill' type="checkbox" disabled={!service.billpayment?'disabled':null} onClick={cardHandler} checked={card.billpayment} label="Bill Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='mobile' type="checkbox" disabled={!service.mobilepayment?'disabled':null} onClick={cardHandler} checked={card.mobilepayment} label="Mobile Payment"/>
       </Form.Group>
       <Form.Group>
       <Form.Check id='zinara' type="checkbox" disabled={!service.zinara?'disabled':null} onClick={cardHandler} checked={card.zinara} label="Zinara"/>
       </Form.Group>
       </Col>
       </Row>
       </Form>
      </Modal.Body>
      <Modal.Footer>
      <Button variant="info" size="sm" onClick={toggleModal} >CLOSE</Button>{' '}
      <Button variant="outline-info" size="sm" onClick={addRecord} >SAVE RECORD</Button>

      </Modal.Footer>
      </Modal>
</Container>
);
}

export default CardSettings;